import { Menu } from '@headlessui/react';
import {
	ChevronDownIcon,
	BarsArrowDownIcon,
} from '@heroicons/react/24/outline';
import { useDispatch } from 'react-redux';
import { changeSorting } from '../../store/slices/usersSlice';
import { classNames } from '../../utils';

export const SORTING_LABEL_MAPPING = {
	first_name: 'First Name, A-Z',
	'-first_name': 'First Name, Z-A',
	last: 'Last Name, A-Z',
	'-last': 'Last Name, Z-A',
	email: 'Email, A-Z',
	'-email': 'Email, Z-A',
	role: 'Role, A-Z',
	'-role': 'Role, Z-A',
};

export const UserSort = ({ sorting }) => {
	const dispatch = useDispatch();
	return (
		<div>
			<Menu as="div" className="relative">
				<Menu.Button className=" w-56 bg-white border border-gray-300 rounded-md shadow-sm px-4 py-2 inline-flex justify-center text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
					<BarsArrowDownIcon className="mr-1.5 h-5 w-5 text-gray-400" />
					{SORTING_LABEL_MAPPING[sorting]}
					<ChevronDownIcon
						className="ml-2.5 -mr-1.5 h-5 w-5 text-gray-400"
						aria-hidden="true"
					/>
				</Menu.Button>
				<Menu.Items className="origin-top-right z-50 absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
					<div className="py-1">
						{Object.keys(SORTING_LABEL_MAPPING).map(item => (
							<Menu.Item key={item}>
								{({ active }) => (
									<button
										onClick={() => {
											dispatch(changeSorting(`${item}`));
										}}
										className={classNames(
											active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
											'block px-4 py-2 text-sm w-full text-left',
										)}
									>
										{SORTING_LABEL_MAPPING[item]}
									</button>
								)}
							</Menu.Item>
						))}
					</div>
				</Menu.Items>
			</Menu>
		</div>
	);
};
