import moment from 'moment';
import { Fragment, useEffect, useState } from 'react';
import { Menu, Transition } from '@headlessui/react';
import {
	ChevronDownIcon,
	ChevronUpIcon,
	HashtagIcon,
} from '@heroicons/react/20/solid';
import {
	HandThumbUpIcon,
	HandThumbDownIcon,
	HandRaisedIcon,
} from '@heroicons/react/24/outline';
import { classNames, formatNumberAsThousands } from '../../utils';
import { useDispatch, useSelector } from 'react-redux';
import {
	setProjectResponsesFilterBy,
	setSelectedResponse,
	setSelectedResponseQuestion,
	startProjectResponseReviewPost,
	startProjectResponsesLoad,
} from '../../store/slices/projectsSlice';
import { QUESTION_TYPES } from '../../constants/questionTypes';
import { useParams } from 'react-router-dom';
import { Spinner } from '../../components/Spinner';
import Histogram from '../../components/Histogram';
import MatrixQuestion from '../../components/Questionnaire/MatrixQuestion';
import YesNoQuestion from '../../components/Questionnaire/YesNoQuestion';
import SingleSelectionQuestion from '../../components/Questionnaire/SingleSelectionQuestion';
import MultipleSelectionQuestion from '../../components/Questionnaire/MultipleSelectionQuestion';
import RatingQuestion from '../../components/Questionnaire/RatingQuestion';
import RankingQuestion from '../../components/Questionnaire/RankingQuestion';
import OpinionScaleQuestion from '../../components/Questionnaire/OpinionScaleQuestion';
import FileUploadArea from '../../components/Questionnaire/FileUploadArea';
import {
	getQuestionHistogram,
	getQuestionMeasurement,
} from '../../api/Services/Measurement';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
	genderNames,
	segmentSesNames,
	educationNames,
	ageNames,
	regionNames,
} from './constant';

const REVIEW_STATUSES = {
	approved: {
		label: 'Approved',
		filterLabel: 'approved',
		textColor: 'text-green-800',
		bgColor: 'bg-green-100',
	},
	pending_review: {
		label: 'Pending Review',
		filterLabel: 'review pending',
		textColor: 'text-slate-800',
		bgColor: 'bg-slate-100',
	},
	rejected: {
		label: 'Rejected',
		filterLabel: 'rejected',
		textColor: 'text-red-800',
		bgColor: 'bg-red-100',
	},
};

function ResponseReviewLabel({
	reviewStatus,
	selected = false,
	basic = false,
	name,
}) {
	if (basic) {
		return (
			<p
				className={classNames(
					selected ? 'text-gray-200' : 'text-gray-500',
					'truncate text-left text-sm ',
				)}
			>
				{REVIEW_STATUSES[reviewStatus].label} {name}
			</p>
		);
	}

	return (
		<span
			className={classNames(
				REVIEW_STATUSES[reviewStatus].bgColor,
				REVIEW_STATUSES[reviewStatus].textColor,
				'items-center rounded px-2 py-1 text-sm font-medium',
			)}
		>
			{REVIEW_STATUSES[reviewStatus].label} {name}
		</span>
	);
}

function ResponseListItem({ project, response, selected }) {
	const dispatch = useDispatch();

	const responseDetails = useSelector(
		state =>
			state.projects.projectDetails[project.nanoid].responses.responseDetails,
	);

	const responseMeta = useSelector(state => state.projects.responseMeta);
	const isLoading = responseMeta[response.nanoid]?.status === 'loading';

	return (
		<li
			key={response.nanoid}
			className={classNames(
				selected ? 'bg-indigo-600' : 'bg-white hover:bg-gray-50',
				'relative px-6 py-5 focus-within:ring-2 focus-within:ring-inset focus-within:ring-blue-600',
			)}
		>
			<div className="flex justify-between space-x-3">
				<div className="min-w-0 flex-1">
					<button
						onClick={() => {
							dispatch(
								setSelectedResponse({
									projectNanoId: project.nanoid,
									responseNanoId: response.nanoid,
								}),
							);
						}}
						className="block focus:outline-none"
					>
						<span className="absolute inset-0" aria-hidden="true" />
						<div className="flex items-center gap-1">
							<p
								className={classNames(
									selected ? 'text-white' : 'text-gray-900',
									'font-mono truncate text-sm font-medium',
								)}
							>
								{response.nanoid}
							</p>
							{isLoading && (
								<Spinner
									className={classNames(
										selected ? 'text-white' : 'text-gray-600',
										'w-4 h-4',
									)}
								/>
							)}
						</div>
						<ResponseReviewLabel
							reviewStatus={responseDetails[response.nanoid].review_status}
							selected={selected}
							basic={true}
						/>
					</button>
				</div>
				<time
					title={moment(response.created).format('MMMM Do YYYY, h:mm:ss a')}
					dateTime={moment(response.created).format('MMMM Do YYYY, h:mm:ss a')}
					className={classNames(
						selected ? 'text-gray-100' : 'text-gray-500',
						'flex-shrink-0 whitespace-nowrap text-sm',
					)}
				>
					{moment(response.created).fromNow()}
				</time>
			</div>
			<div className="mt-1">
				<p
					className={classNames(
						selected ? 'text-gray-200' : 'text-gray-600',
						'line-clamp-2 text-sm ',
					)}
				>
					{/* Response contains {Object.keys(response.answers)} answers. */}
					{response.demographics['segment-ses'] && (
						<span className="text-xs">
							{response.demographics['segment-ses']
								.map(segment => segmentSesNames[segment])
								.join()}{' '}
							/ {ageNames[response.demographics['yas']]} /{' '}
							{genderNames[response.demographics['cinsiyet']]} /{' '}
							{educationNames[response.demographics['egitim-duzeyi']]} /{' '}
							{[response.demographics['sehir']].join(', ')}
							{response.demographics['bolge'].length > 0 &&
								`/ ${response.demographics['bolge']
									.map(region => regionNames[region])
									.join(', ')}`}
						</span>
					)}
				</p>
			</div>
		</li>
	);
}

function FilterSelector({ project, responses }) {
	const dispatch = useDispatch();
	return (
		<>
			Showing{' '}
			<Menu as="div" className="relative inline-block">
				<div>
					<Menu.Button>
						<span className="underline cursor-pointer text-gray-800">
							{REVIEW_STATUSES[responses.filterBy].filterLabel}
						</span>
					</Menu.Button>
				</div>

				<Transition
					as={Fragment}
					enter="transition ease-out duration-100"
					enterFrom="transform opacity-0 scale-95"
					enterTo="transform opacity-100 scale-100"
					leave="transition ease-in duration-75"
					leaveFrom="transform opacity-100 scale-100"
					leaveTo="transform opacity-0 scale-95"
				>
					<Menu.Items className="absolute left-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
						<div className="py-1">
							{Object.keys(REVIEW_STATUSES).map((status, index) => {
								return (
									<Menu.Item key={index}>
										{({ active }) => (
											<button
												onClick={() => {
													dispatch(
														setProjectResponsesFilterBy({
															projectNanoId: project.nanoid,
															filterBy: status,
														}),
													);
												}}
												className={classNames(
													active
														? 'bg-gray-100 text-gray-900'
														: 'text-gray-700',
													'w-full block text-left px-4 py-2 text-sm',
												)}
											>
												{REVIEW_STATUSES[status].label}
											</button>
										)}
									</Menu.Item>
								);
							})}
						</div>
					</Menu.Items>
				</Transition>
			</Menu>{' '}
			responses
		</>
	);
}

function ResponseList({ project, responses }) {
	const dispatch = useDispatch();

	return (
		<aside className="hidden xl:order-first xl:block xl:flex-shrink-0">
			<div className="relative flex h-full w-96 flex-col border-r border-gray-200 bg-gray-100">
				<div className="flex-shrink-0">
					<div className="flex h-16 flex-col justify-center bg-white px-6">
						<div className="flex items-baseline space-x-3 justify-between">
							<h2 className="text-lg font-medium text-gray-900">Moderation</h2>
							{responses.status !== 'loading' && (
								<div className="flex gap-1">
									<span
										title={`Total: ${responses.counts.total}`}
										className="inline-flex items-center rounded-full bg-cyan-100 px-2 py-0.5 text-xs font-medium text-cyan-800"
									>
										{formatNumberAsThousands(responses.counts.total)}
									</span>
									<span
										title={`Approved: ${responses.counts.approved}`}
										className="inline-flex items-center rounded-full 
										bg-green-100 px-2 py-0.5 text-xs font-medium text-green-800"
									>
										{formatNumberAsThousands(responses.counts.approved)}
									</span>
									<span
										title={`Pending Review: ${responses.counts.pending_review}`}
										className="inline-flex items-center rounded-full bg-slate-100 px-2 py-0.5 text-xs font-medium text-slate-800"
									>
										{formatNumberAsThousands(responses.counts.pending_review)}
									</span>
									<span
										title={`Rejected: ${responses.counts.rejected}`}
										className="inline-flex items-center rounded-full bg-red-100 px-2 py-0.5 text-xs font-medium text-red-800"
									>
										{formatNumberAsThousands(responses.counts.rejected)}
									</span>
								</div>
							)}
						</div>
					</div>
					<div className="border-b border-t border-gray-200 bg-gray-50 px-6 py-2 text-sm font-medium text-gray-500">
						<FilterSelector project={project} responses={responses} />
					</div>
				</div>
				<nav
					aria-label="Message list"
					className="min-h-0 flex-1 overflow-scroll"
				>
					<ul
						role="list"
						className="divide-y divide-gray-200 border-b border-gray-200"
					>
						{responses.responseList.map(response => (
							<ResponseListItem
								key={response.nanoid}
								response={response}
								project={project}
								selected={responses.selectedResponseNanoId === response.nanoid}
							/>
						))}
					</ul>
					{responses.nextUrl && (
						<div className="py-5 text-center">
							<button
								type="button"
								disabled={responses.status === 'loading'}
								onClick={() => {
									dispatch(
										startProjectResponsesLoad({
											projectNanoId: project.nanoid,
											append: true,
											nextUrl: responses.nextUrl,
										}),
									);
								}}
								className="rounded bg-white px-2 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 disabled:cursor-not-allowed disabled:opacity-30"
							>
								Load More
							</button>
						</div>
					)}
				</nav>
			</div>
		</aside>
	);
}

function QuestionConfigList({ question }) {
	return (
		<div className="flex flex-col">
			<div className="border-b p-2">
				{question.config.required && 'Required'}
				{!question.config.required && 'Not required'}
			</div>
			{(question.question_type === 'single_selection' ||
				question.question_type === 'multiple_selection') && (
				<>
					{question.config.show_other && (
						<div className="border-b p-2">Show other</div>
					)}
					{question.config.is_randomized && (
						<div className="border-b p-2">Randomized choices</div>
					)}
					{question.question_type === 'multiple_selection' && (
						<>
							{question.config.selection_count_type === 'unlimited' && (
								<div className="border-b p-2">Unlimited selections</div>
							)}
							{question.config.selection_count_type === 'exact_number' && (
								<div className="border-b p-2">Exact number of selections</div>
							)}
							{question.config.selection_count_type === 'range' && (
								<div className="border-b p-2">Range of selections</div>
							)}
							<div className="border-b p-2">
								Min Selections: {question.config.min_selection_count}
							</div>
							<div className="border-b p-2">
								Max Selections: {question.config.max_selection_count}
							</div>
						</>
					)}
				</>
			)}
			{question.question_type === 'text' && (
				<>
					<div className="border-b p-2">
						{question.config.is_multiline && 'Multiline'}
						{!question.config.is_multiline && 'Not multiline'}
					</div>
					{question.config.min_characters && (
						<div className="border-b p-2">
							Min Chars: {question.config.min_characters}
						</div>
					)}
					{question.config.max_characters && (
						<div className="border-b p-2">
							Max Chars: {question.config.max_characters}
						</div>
					)}
				</>
			)}
			{question.question_type === 'number' && (
				<>
					{question.config.is_custom_range && (
						<div className="border-b p-2">Custom Range</div>
					)}
					{question.config.is_custom_range && (
						<div className="border-b p-2">Min: {question.config.min_value}</div>
					)}
					{question.config.is_custom_range && (
						<div className="border-b p-2">Max: {question.config.max_value}</div>
					)}
				</>
			)}
			{question.question_type === 'matrix' && (
				<>
					{!question.config.is_multiselection && (
						<div className="border-b p-2">Single Selection</div>
					)}
					{question.config.is_multiselection && (
						<div className="border-b p-2">Multiple Selection</div>
					)}
				</>
			)}
			{question.question_type === 'file_upload' && (
				<>
					<div className="border-b p-2">
						Min Files: {question.config.min_file_count}
					</div>
					<div className="border-b p-2">
						Max Files: {question.config.max_file_count}
					</div>
				</>
			)}
		</div>
	);
}

function ResponseReviewStatusChanger({ response, disabled }) {
	const dispatch = useDispatch();
	const { nanoid } = useParams();

	const responseMeta = useSelector(state => state.projects.responseMeta);
	const { projectDetails } = useSelector(state => state.projects);
	const project = projectDetails[nanoid];

	const rejectionDisabled =
		responseMeta[response.nanoid]?.selectedQuestionNanoId === '';

	const rejectionReasons = [
		['inappropriate_language', 'Inappropriate Language'],
		['inappropriate_image', 'Inappropriate Image'],
		['personal_data_shared', 'Personal Data Shared'],
		['too_short', 'Too Short'],
		['too_quick', 'Too Quick'],
		['logic_error', 'Logic Error'],
	];

	const startResponseReviewPost = (reviewStatus, rejectionReason) => {
		const responseDetailsKeys = Object.keys(project.responses.responseDetails);
		const lastResponseDetailKey =
			responseDetailsKeys[responseDetailsKeys.length - 1];
		const lastResponseDetail =
			project.responses.responseDetails[lastResponseDetailKey];

		let requestData = {
			projectNanoId: nanoid,
			responseNanoId: response.nanoid,
			reviewStatus: reviewStatus,
		};

		if (reviewStatus === 'rejected') {
			requestData = {
				...requestData,
				rejectionReason: rejectionReason,
				questionNanoId: responseMeta[response.nanoid].selectedQuestionNanoId,
			};
		}

		dispatch(startProjectResponseReviewPost(requestData));

		if (
			lastResponseDetail.nanoid === response.nanoid &&
			lastResponseDetail?.review_status !== reviewStatus
		) {
			project.responses.nextUrl &&
				dispatch(
					startProjectResponsesLoad({
						projectNanoId: project.nanoid,
						append: true,
						nextUrl: project.responses.nextUrl,
					}),
				);
		}
	};

	return (
		<div className="relative z-50">
			<div className="isolate inline-flex rounded-md shadow-sm sm:space-x-3 sm:shadow-none">
				<span className="inline-flex sm:shadow-sm">
					<button
						onClick={() => startResponseReviewPost('approved')}
						disabled={response.review_status === 'approved' || disabled}
						type="button"
						className={classNames(
							response.review_status === 'approved'
								? 'bg-gray-200'
								: 'text-gray-900 bg-white hover:bg-gray-50',
							'disabled:cursor-not-allowed disabled:opacity-30',
							'relative inline-flex items-center gap-x-1.5 rounded-l-md px-3 py-2 text-sm font-semibold  ring-1 ring-inset ring-gray-300 hover:z-10 focus:z-10',
						)}
					>
						<HandThumbUpIcon
							className="-ml-0.5 h-5 w-5 text-gray-400"
							aria-hidden="true"
						/>
						Approve
					</button>
					<button
						type="button"
						disabled={response.review_status === 'pending_review' || disabled}
						onClick={() => startResponseReviewPost('pending_review')}
						className={classNames(
							response.review_status === 'pending_review'
								? 'bg-gray-200'
								: 'text-gray-900 bg-white hover:bg-gray-50',
							'disabled:cursor-not-allowed disabled:opacity-30',
							'relative -ml-px inline-flex items-center gap-x-1.5 px-3 py-2 text-sm font-semibold ring-1 ring-inset ring-gray-300 hover:z-10 focus:z-10',
						)}
					>
						<HandRaisedIcon
							className="-ml-0.5 h-5 w-5 text-gray-400"
							aria-hidden="true"
						/>
						Pending Review
					</button>

					<Menu as="div" className="relative inline-block text-left">
						<div className="relative">
							<Menu.Button
								disabled={
									response.review_status === 'rejected' ||
									disabled ||
									rejectionDisabled
								}
								className={'disabled:cursor-not-allowed disabled:opacity-30'}
							>
								<div
									title={rejectionDisabled ? 'Select a question first' : ''}
									className={classNames(
										response.review_status === 'rejected'
											? 'bg-gray-200'
											: 'text-gray-900 bg-white hover:bg-gray-50',

										'relative -ml-px inline-flex items-center gap-x-1.5 rounded-r-md px-3 py-2 text-sm font-semibold ring-1 ring-inset ring-gray-300 hover:z-10 focus:z-10',
									)}
								>
									<HandThumbDownIcon
										className="-ml-0.5 h-5 w-5 text-gray-400"
										aria-hidden="true"
									/>
									Reject
								</div>
							</Menu.Button>
						</div>

						<Transition
							as={Fragment}
							enter="transition ease-out duration-100"
							enterFrom="transform opacity-0 scale-95"
							enterTo="transform opacity-100 scale-100"
							leave="transition ease-in duration-75"
							leaveFrom="transform opacity-100 scale-100"
							leaveTo="transform opacity-0 scale-95"
						>
							<Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
								<div className="py-1">
									{rejectionReasons.map((rejectionReason, index) => (
										<Menu.Item key={index}>
											{({ active }) => (
												<button
													onClick={() =>
														startResponseReviewPost(
															'rejected',
															rejectionReason[0],
														)
													}
													type="button"
													className={classNames(
														active
															? 'bg-gray-100 text-gray-900'
															: 'text-gray-700',
														'flex w-full justify-between px-4 py-2 text-sm',
													)}
												>
													<span>{rejectionReason[1]}</span>
												</button>
											)}
										</Menu.Item>
									))}
								</div>
							</Menu.Items>
						</Transition>
					</Menu>
				</span>
			</div>
		</div>
	);
}

function QuestionAnswer({ question, index, selected, response }) {
	const dispatch = useDispatch();
	const [questionHistogramData, setQuestionHistogramData] = useState();

	useEffect(() => {
		let isMounted = true;

		const fetchData = async () => {
			const result = await getQuestionHistogram({
				questionNanoId: question.nanoid,
			});
			const questionMeasurement = await getQuestionMeasurement({
				questionNanoId: question.nanoid,
				responseNanoId: response.nanoid,
			});

			if (isMounted) {
				const dataToSet = {
					entries: result.entries,
					binWidth: result.bin_width,
				};

				if (questionMeasurement) {
					dataToSet['totalTime'] = questionMeasurement.total_time;
					dataToSet['numKeystrokes'] = questionMeasurement.num_keystrokes;
				}

				setQuestionHistogramData(dataToSet);
			}
		};

		fetchData();

		return function cleanup() {
			isMounted = false;
		};
	}, [question.nanoid, response.nanoid]);

	return (
		<li
			key={question.nanoid}
			className={classNames(
				'border-l-4',
				selected ? 'border-t-2 border-r-2 border-b-2' : '',
				QUESTION_TYPES[question.question_type].borderColor,
				'bg-white shadow rounded group',
			)}
		>
			<div className="border-b border-gray-200">
				<div className="px-4 py-4 sm:flex sm:justify-between items-baseline sm:items-center">
					<div className="flex gap-2 items-center">
						<div
							className={classNames(
								QUESTION_TYPES[question.question_type].borderColor,
								QUESTION_TYPES[question.question_type].textColor,
								'flex flex-col justify-around border-2 text-sm font-semibold rounded w-10 h-10 text-center shrink-0',
							)}
						>
							<span
								className={classNames(
									!selected ? 'group-hover:hidden' : 'hidden',
								)}
							>
								Q{index + 1}
							</span>
							<div
								className={classNames(
									!selected ? 'hidden group-hover:block' : '',
									' text-center',
								)}
							>
								<input
									type="checkbox"
									checked={selected}
									onChange={() => {
										dispatch(
											setSelectedResponseQuestion({
												responseNanoId: response.nanoid,
												selectedQuestionNanoId: question.nanoid,
											}),
										);
									}}
									className={classNames(
										QUESTION_TYPES[question.question_type].textColor,
										'h-4 w-4 rounded border-gray-300',
									)}
								/>
							</div>
						</div>
						<div>
							<h3 className="text-base font-medium">
								<span className="text-gray-900">{question.title}</span>
							</h3>
							<p className=" whitespace-nowrap text-sm text-gray-600">
								{question.question_type}&nbsp;&middot;&nbsp;{question.nanoid}
							</p>
						</div>
					</div>
					{questionHistogramData && (
						<div className=" w-40 h-11 border rounded">
							<Histogram
								data={questionHistogramData.entries}
								binWidth={questionHistogramData.binWidth}
								markedValue={questionHistogramData.totalTime}
							/>
						</div>
					)}
				</div>
			</div>
			<div className="flex">
				<div className="p-4 text-sm text-gray-800 w-9/12">
					<div className="mb-10">
						{response.answers[question.nanoid] !== undefined &&
							response.answers[question.nanoid] !== null && (
								<div>
									{question.question_type === 'single_selection' && (
										<SingleSelectionQuestion
											key={response.nanoid}
											question={question}
											response={response.answers}
											isReadOnly={true}
											showNextQuestionButton={false}
										/>
									)}
									{question.question_type === 'multiple_selection' && (
										<MultipleSelectionQuestion
											key={response.nanoid}
											question={question}
											isReadOnly={true}
											response={response.answers}
											showNextQuestionButton={false}
										/>
									)}
									{question.question_type === 'yes_no' && (
										<YesNoQuestion
											key={response.nanoid}
											question={question}
											isReadOnly={true}
											response={response.answers}
											showNextQuestionButton={false}
										/>
									)}
									{(question.question_type === 'text' ||
										question.question_type === 'number') && (
										<div className="flex flex-col gap-4">
											<div className="flex">
												<div className="border rounded font-mono flex">
													<div className="border-r py-1 px-2">
														<HashtagIcon className="w-4 h-4 mt-0.5" />
													</div>
													<div className="py-1 px-2">
														{response.answers[question.nanoid]?.length || '0'}{' '}
														chars
													</div>
													{questionHistogramData && (
														<div className="py-1 px-2 border-l">
															{questionHistogramData.numKeystrokes} keystrokes
														</div>
													)}
												</div>
											</div>

											<div className="font-mono">
												{response.answers[question.nanoid]}
											</div>
										</div>
									)}
									{question.question_type === 'matrix' && (
										<div className="overflow-x-scroll p-0.5">
											<MatrixQuestion
												key={response.nanoid}
												question={question}
												isReadOnly={true}
												response={response.answers}
												showNextQuestionButton={false}
											/>
										</div>
									)}
									{question.question_type === 'rating' && (
										<RatingQuestion
											key={response.nanoid}
											question={question}
											isReadOnly={true}
											response={response.answers}
											showNextQuestionButton={false}
										/>
									)}
									{question.question_type === 'ranking' && (
										<RankingQuestion
											key={response.nanoid}
											question={question}
											isReadOnly={true}
											response={response.answers}
											showNextQuestionButton={false}
										/>
									)}
									{(question.question_type === 'nps' ||
										question.question_type === 'opinion_scale') && (
										<OpinionScaleQuestion
											key={response.nanoid}
											question={question}
											isReadOnly={true}
											response={response.answers}
											showNextQuestionButton={false}
										/>
									)}
									{question.question_type === 'file_upload' && (
										<FileUploadArea
											key={response.nanoid}
											question={question}
											isReadOnly={true}
											response={response.answers}
											showNextQuestionButton={false}
										/>
									)}
								</div>
							)}
					</div>
				</div>
				<div className="bg-slate-100 w-3/12 border-l">
					<div className="text-xs text-gray-600">
						{question.config.required && (
							<QuestionConfigList question={question} />
						)}
					</div>
				</div>
			</div>
		</li>
	);
}

function ResponseDetail({ project, questionnaireNanoId, response }) {
	const dispatch = useDispatch();
	const questionnaireDetails = useSelector(
		state => state.projects.questionnaireDetails,
	);
	const questionList = questionnaireDetails[questionnaireNanoId]
		? questionnaireDetails[questionnaireNanoId].questionList
		: null;

	const responseMeta = useSelector(state => state.projects.responseMeta);
	const questionnaireMeasurementHistogram = useSelector(
		state => state.projects.questionnaireMeasurementHistogram,
	);

	const isLoading = responseMeta[response.nanoid]?.status === 'loading';
	const selectedQuestionNanoId =
		responseMeta[response.nanoid]?.selectedQuestionNanoId;

	const previousResponseNanoId = useSelector(
		state =>
			state.projects.projectDetails[project.nanoid].responses
				.previousResponseNanoId,
	);
	const nextResponseNanoId = useSelector(
		state =>
			state.projects.projectDetails[project.nanoid].responses
				.nextResponseNanoId,
	);

	const hasPreviousResponse =
		previousResponseNanoId !== undefined && previousResponseNanoId !== '';
	const hasNextResponse =
		nextResponseNanoId !== undefined && nextResponseNanoId !== '';

	const handleCopy = () => {
		toast.success('Response nanoid has been copied to the clipboard.', {
			position: toast.POSITION.TOP_RIGHT,
			autoClose: 2000,
		});
	};

	if (!questionList) {
		return <></>;
	}

	return (
		<section
			aria-labelledby="message-heading"
			className="flex min-w-0 flex-1 flex-col xl:order-last h-full overflow-hidden"
		>
			{/* Top section */}
			<div className="flex-shrink-0 border-b border-gray-200 bg-white">
				{/* Toolbar*/}
				<div className="flex h-16 flex-col justify-center">
					<div className="px-4 sm:px-6 lg:px-8">
						<div className="flex justify-between py-3">
							<div className="flex items-center gap-2">
								<ResponseReviewStatusChanger
									response={response}
									disabled={isLoading}
								/>
								{isLoading && <Spinner className={'text-gray-600 w-5 h-5'} />}
							</div>

							{/* Right buttons */}
							<nav aria-label="Pagination">
								<span className="isolate inline-flex rounded-md shadow-sm">
									<button
										disabled={!hasPreviousResponse}
										onClick={() =>
											dispatch(
												setSelectedResponse({
													projectNanoId: project.nanoid,
													responseNanoId: previousResponseNanoId,
												}),
											)
										}
										className="relative inline-flex items-center rounded-l-md bg-white px-3 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:z-10 hover:bg-gray-50 focus:z-10 disabled:cursor-not-allowed disabled:opacity-30"
									>
										<span className="sr-only">Next</span>
										<ChevronUpIcon className="h-5 w-5" aria-hidden="true" />
									</button>
									<button
										onClick={() =>
											dispatch(
												setSelectedResponse({
													projectNanoId: project.nanoid,
													responseNanoId: nextResponseNanoId,
												}),
											)
										}
										disabled={!hasNextResponse}
										className="relative -ml-px inline-flex items-center rounded-r-md bg-white px-3 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:z-10 hover:bg-gray-50 focus:z-10 disabled:cursor-not-allowed disabled:opacity-30"
									>
										<span className="sr-only">Previous</span>
										<ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
									</button>
								</span>
							</nav>
						</div>
					</div>
				</div>
				{/* Message header */}
			</div>

			<div className="relative min-h-0 flex-1 h-screen mb-48">
				<div className="bg-white pb-6 pt-5 shadow">
					<div className="px-4 sm:flex sm:items-center sm:justify-between sm:px-6 lg:px-8">
						<div className="sm:w-0 sm:flex-1">
							<CopyToClipboard text={response.nanoid} onCopy={handleCopy}>
								<h1
									id="message-heading"
									className="text-lg font-medium text-gray-900 cursor-pointer"
								>
									{response.nanoid}
								</h1>
							</CopyToClipboard>
							<ToastContainer />
							<p className="mt-1 truncate text-sm text-gray-500">
								{moment(response.created).format('MMMM Do YYYY, h:mm:ss a')}
							</p>
							<div className="mt-2">
								<ResponseReviewLabel
									reviewStatus={response.review_status}
									basic={false}
								/>
							</div>
						</div>

						{questionnaireMeasurementHistogram[project.questionnaire] && (
							<div className="flex flex-col gap-1">
								<div className="text-right text-xs uppercase text-zinc-500">
									Response Time
								</div>
								<div className="w-48 h-14 border rounded">
									<Histogram
										data={
											questionnaireMeasurementHistogram[project.questionnaire]
												.entries
										}
										binWidth={
											questionnaireMeasurementHistogram[project.questionnaire]
												.binWidth
										}
										markedValue={
											project.responses.responseMeasurements[response.nanoid]
												? project.responses.responseMeasurements[
														response.nanoid
												  ].total_time
												: null
										}
									/>
								</div>
							</div>
						)}
					</div>
				</div>
				<ul
					role="list"
					className="space-y-2 pt-4 pb-8 sm:space-y-4 sm:px-6 lg:px-8 h-full overflow-y-auto"
				>
					{questionList.map((question, index) => (
						<QuestionAnswer
							key={question.nanoid}
							index={index + 1}
							question={question}
							selected={selectedQuestionNanoId === question.nanoid}
							response={response}
						/>
					))}
				</ul>
			</div>
		</section>
	);
}

export default function ModerationTab({ project }) {
	const dispatch = useDispatch();
	const responses = project.responses;

	//const responseCounts = useSelector(
	//	state => state.projectDetails[project.nanoid].responses,
	//);

	useEffect(() => {
		dispatch(
			startProjectResponsesLoad({
				projectNanoId: project.nanoid,
				questionnaireNanoId: project.questionnaire,
				filterBy: responses.filterBy,
			}),
		);
	}, [dispatch, project.nanoid, project.questionnaire, responses.filterBy]);

	return (
		<>
			<div className="flex flex-col">
				{/* Bottom section */}
				<div className="flex min-h-0 flex-1">
					{/* Main area */}
					<main className="relative min-w-0 flex-1 border border-gray-200 xl:flex  h-[70vh] overflow-hidden">
						{responses.responseList.length > 0 &&
							responses.selectedResponseNanoId !== '' && (
								<ResponseDetail
									project={project}
									questionnaireNanoId={project.questionnaire}
									response={
										responses.responseDetails[responses.selectedResponseNanoId]
									}
								/>
							)}
						{responses.counts.total === 0 && responses.status === 'idle' && (
							<div className="text-center w-full mt-20 text-sm text-gray-600">
								This project has not received any response yet.
							</div>
						)}
						{responses.responseList.length === 0 &&
							responses.status === 'loading' && (
								<div className="w-full mt-20">
									<Spinner className="mx-auto text-gray-600 w-10 h-10" />
								</div>
							)}
						<ResponseList project={project} responses={responses} />
					</main>
				</div>
			</div>
		</>
	);
}
