import {
	getStats,
	getStatsFail,
	getUnderApprovalProjects,
	getUnderApprovalProjectsFail,
	getProjectsWithoutAction,
	getProjectsWithoutActionFail,
	getProjectsNearingEstimatedTime,
	getProjectsNearingEstimatedTimeFail,
} from '../../store/slices/dashboardSlice';
import { sorbunuApi } from '../../api/axios';
import { endPoints } from '../../endPoints';
import { call, put, takeLatest } from 'redux-saga/effects';

function* fetchStats() {
	try {
		const completedProjectsOnTime = yield call(() =>
			sorbunuApi.get(endPoints.dashboard.GET_COMPLETED_PROJECTS_ON_TIME()),
		);

		const accountsWithAtLeastOneProject = yield call(() =>
			sorbunuApi.get(
				endPoints.dashboard.GET_ACCOUNTS_WITH_AT_LEAST_ONE_PROJECT(),
			),
		);

		const responseTimeSuccessRate = yield call(() =>
			sorbunuApi.get(endPoints.dashboard.GET_RESPONSE_TIME_SUCCESS_RATE()),
		);

		const statsData = [
			completedProjectsOnTime.data,
			accountsWithAtLeastOneProject.data,
			responseTimeSuccessRate.data,
		];

		yield put(getStats(statsData));
	} catch (err) {
		yield put(getStatsFail(err.response.data));
	}
}

function* fetchUnderApprovalProjects() {
	try {
		const response = yield call(() =>
			sorbunuApi.get(endPoints.dashboard.GET_UNDER_APPROVAL_PROJECTS()),
		);

		yield put(getUnderApprovalProjects(response.data.results));
	} catch (err) {
		yield put(getUnderApprovalProjectsFail(err.response.data));
	}
}

function* fetchProjectsWithoutAction() {
	try {
		const response = yield call(() =>
			sorbunuApi.get(endPoints.dashboard.GET_PROJECTS_WITHOUT_ACTION()),
		);

		yield put(getProjectsWithoutAction(response.data.results));
	} catch (err) {
		yield put(getProjectsWithoutActionFail(err.response.data));
	}
}

function* fetchProjectsNearingEstimatedTime() {
	try {
		const response = yield call(() =>
			sorbunuApi.get(endPoints.dashboard.GET_PROJECTS_NEARING_ESTIMATED_TIME()),
		);

		yield put(getProjectsNearingEstimatedTime(response.data.results));
	} catch (err) {
		yield put(getProjectsNearingEstimatedTimeFail(err.response.data));
	}
}

export function* dashboardModuleSaga() {
	yield takeLatest('dashboard/fetch_stats', fetchStats);
	yield takeLatest(
		'dashboard/fetch_under_approval_projects',
		fetchUnderApprovalProjects,
	);
	yield takeLatest(
		'dashboard/fetch_projects_without_action',
		fetchProjectsWithoutAction,
	);
	yield takeLatest(
		'dashboard/fetch_projects_nearing_estimated_time',
		fetchProjectsNearingEstimatedTime,
	);
}
