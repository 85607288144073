import { useEffect, useState } from 'react';
import { RadioGroup } from '@headlessui/react';
import { classNames } from '../utils/utils';
import * as _ from 'lodash';
import NextButton from '../NextButton';

export default function OpinionScaleQuestion({
	question,
	handleQuestionSubmit,
	lastQuestion,
	isReadOnly = false,
	response = null,
	showNextQuestionButton = true,
}) {
	const isRequired = question.config.required;
	const [error, setError] = useState(isRequired);

	let initialValue = null;

	// Are we rendering an existing response?
	if (response) {
		if (
			response[question.nanoid] !== undefined &&
			response[question.nanoid] !== null
		) {
			initialValue = response[question.nanoid];
		}
	}

	const [values, setValues] = useState(initialValue);

	useEffect(() => {
		if (isRequired) {
			values !== null && values !== undefined
				? setError(false)
				: setError(true);
		}
	}, [isRequired, values]);

	const formSubmissionHandler = e => {
		e.preventDefault();

		handleQuestionSubmit({
			question: question,
			answer: values,
		});
	};
	return (
		<form onSubmit={formSubmissionHandler}>
			<RadioGroup
				value={values}
				onChange={setValues}
				className="mt-2"
				disabled={isReadOnly}
			>
				<div className="flex gap-2">
					{_.range(question.config.minimum, question.config.maximum + 1).map(
						i => (
							<RadioGroup.Option
								key={i}
								value={i}
								className={({ active, checked }) =>
									classNames(
										active ? 'ring-2 ring-offset-2 ring-mint-500' : '',
										checked
											? 'bg-mint-600 border-transparent text-white hover:bg-mint-700'
											: 'bg-white border-gray-200 text-gray-900 hover:bg-gray-50',
										'w-full cursor-pointer focus:outline-none border rounded-md py-2 flex items-center justify-center text-sm font-medium uppercase sm:flex-1',
									)
								}
							>
								<RadioGroup.Label as="span">{i}</RadioGroup.Label>
							</RadioGroup.Option>
						),
					)}
				</div>
			</RadioGroup>
			<div className="flex justify-between mt-3 text-sm text-mint-800">
				<span className="w-1/5 text-left">{question.config.min_label}</span>
				<span className="w-1/5 text-center">{question.config.mid_label}</span>
				<span className="w-1/5 text-right">{question.config.max_label}</span>
			</div>
			{showNextQuestionButton && (
				<NextButton disabled={error} lastQuestion={lastQuestion} />
			)}
		</form>
	);
}
