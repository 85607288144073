import { Menu } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import { useDispatch } from 'react-redux';
import { changeStatus } from '../../store/slices/projectsSlice';
import { classNames } from '../../utils';

export const PROJECT_STATUS_TEXT_MAPPING = {
	all: 'All',
	draft: 'Draft',
	under_approval: 'Under Approval',
	under_edit: 'Under Edit',
	approved: 'Approved',
	running: 'Running',
	paused: 'Paused',
	completed: 'Completed',
};

export const ProjectFilterStatus = ({ status }) => {
	const dispatch = useDispatch();

	return (
		<div>
			<Menu as="div" className="relative mr-3">
				<Menu.Button className="w-max bg-white border border-gray-300 rounded-md shadow-sm px-4 py-2 inline-flex justify-center text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
					<svg
						xmlns="http://www.w3.org/2000/svg"
						fill="none"
						viewBox="0 0 24 24"
						strokeWidth={1.5}
						stroke="currentColor"
						className="mr-2 h-5 w-5 text-gray-400"
					>
						<path
							strokeLinecap="round"
							strokeLinejoin="round"
							d="M12 3c2.755 0 5.455.232 8.083.678.533.09.917.556.917 1.096v1.044a2.25 2.25 0 01-.659 1.591l-5.432 5.432a2.25 2.25 0 00-.659 1.591v2.927a2.25 2.25 0 01-1.244 2.013L9.75 21v-6.568a2.25 2.25 0 00-.659-1.591L3.659 7.409A2.25 2.25 0 013 5.818V4.774c0-.54.384-1.006.917-1.096A48.32 48.32 0 0112 3z"
						/>
					</svg>

					{PROJECT_STATUS_TEXT_MAPPING[status]}
					<ChevronDownIcon
						className="ml-2.5 -mr-1.5 h-5 w-5 text-gray-400"
						aria-hidden="true"
					/>
				</Menu.Button>
				<Menu.Items className="origin-top-right z-40 absolute right-0 mt-2 w-40 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
					<div className="py-1">
						<Menu.Item>
							{({ active }) => (
								<button
									onClick={() => {
										dispatch(changeStatus('all'));
									}}
									className={classNames(
										active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
										'block px-4 py-2 text-sm w-full text-left',
									)}
								>
									All
								</button>
							)}
						</Menu.Item>
						<Menu.Item>
							{({ active }) => (
								<button
									onClick={() => {
										dispatch(changeStatus('draft'));
									}}
									className={classNames(
										active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
										'block px-4 py-2 text-sm w-full text-left',
									)}
								>
									Draft
								</button>
							)}
						</Menu.Item>
						<Menu.Item>
							{({ active }) => (
								<button
									onClick={() => {
										dispatch(changeStatus('under_approval'));
									}}
									className={classNames(
										active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
										'block px-4 py-2 text-sm w-full text-left',
									)}
								>
									Under Approval
								</button>
							)}
						</Menu.Item>
						<Menu.Item>
							{({ active }) => (
								<button
									onClick={() => {
										dispatch(changeStatus('under_edit'));
									}}
									className={classNames(
										active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
										'block px-4 py-2 text-sm w-full text-left',
									)}
								>
									Under Edit
								</button>
							)}
						</Menu.Item>
						<Menu.Item>
							{({ active }) => (
								<button
									onClick={() => {
										dispatch(changeStatus('approved'));
									}}
									className={classNames(
										active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
										'block px-4 py-2 text-sm w-full text-left',
									)}
								>
									Approved
								</button>
							)}
						</Menu.Item>
						<Menu.Item>
							{({ active }) => (
								<button
									onClick={() => {
										dispatch(changeStatus('running'));
									}}
									className={classNames(
										active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
										'block px-4 py-2 text-sm w-full text-left',
									)}
								>
									Running
								</button>
							)}
						</Menu.Item>
						<Menu.Item>
							{({ active }) => (
								<button
									onClick={() => {
										dispatch(changeStatus('paused'));
									}}
									className={classNames(
										active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
										'block px-4 py-2 text-sm w-full text-left',
									)}
								>
									Paused
								</button>
							)}
						</Menu.Item>
						<Menu.Item>
							{({ active }) => (
								<button
									onClick={() => {
										dispatch(changeStatus('completed'));
									}}
									className={classNames(
										active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
										'block px-4 py-2 text-sm w-full text-left',
									)}
								>
									Completed
								</button>
							)}
						</Menu.Item>
					</div>
				</Menu.Items>
			</Menu>
		</div>
	);
};
