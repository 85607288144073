import React from 'react';
import moment from 'moment';

const PromoCodesList = ({ title, projects }) => {
	return (
		<div className="mt-10">
			<h2>{title}</h2>
			<div className="-mx-4 mt-2 overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:-mx-6 md:mx-0 md:rounded-lg">
				<table className="min-w-full divide-y divide-gray-300">
					<thead className="bg-gray-50">
						<tr>
							<th
								scope="col"
								className="py-3.5 pl-4 pr-3 text-left text-sm font-medium text-gray-500 sm:pl-6"
							>
								Project Title
							</th>
							<th
								scope="col"
								className="hidden px-3 py-3.5 text-left text-sm font-medium text-gray-500 lg:table-cell"
							>
								Status
							</th>
							<th
								scope="col"
								className="px-3 py-3.5 text-left text-sm font-medium text-gray-500"
							>
								Created
							</th>
							<th
								scope="col"
								className="px-3 py-3.5 text-left text-sm font-medium text-gray-500"
							>
								Company
							</th>
							<th
								scope="col"
								className="px-3 py-3.5 text-left text-sm font-medium text-gray-500"
							>
								User
							</th>
						</tr>
					</thead>
					<tbody className="divide-y divide-gray-200 bg-white">
						{projects.length > 0 &&
							projects.map(project => (
								<tr key={project.nanoid}>
									<td className="w-full max-w-0 py-4 pl-4 pr-3 text-sm font-medium text-gray-500 sm:w-auto sm:max-w-none sm:pl-6">
										{project.title}
									</td>
									<td className="hidden px-3 py-4 text-sm text-gray-500 lg:table-cell">
										{project.status}
									</td>
									<td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
										{moment(project.created).format('MMMM Do YYYY, h:mm:ss a')}
									</td>
									<td className="px-be3 py-4 text-sm text-gray-500">
										{project.user?.company?.name}
									</td>
									<td className="px-3 py-4 text-sm text-gray-500">
										{project.user?.first_name} {project.user?.last_name}
									</td>
								</tr>
							))}
						{projects.length === 0 && (
							<tr>
								<td
									colSpan={5}
									className="text-center text-gray-500 text-sm py-4 px-3 sm:table-cell"
								>
									There is no record to show here
								</td>
							</tr>
						)}
					</tbody>
				</table>
			</div>
		</div>
	);
};

export default PromoCodesList;
