import { object, string } from 'yup';
import { ERROR_MESSAGES } from '../../utils/error-messages';
const { required } = ERROR_MESSAGES;

export const updateMarketingDetailsSchema = object({
	title: string().nullable().min(3, 'Too Short').required(required('title')),
	description: string()
		.nullable()
		.min(3, 'Too Short')
		.required(required('description')),
});

export const genderNames = {
	'I7-kwzEwH3bTz47m': 'Kadın',
	zZSmdQInlGDNPWb0: 'Erkek',
	S32ZAuvGFK48BLLR: 'Belirtmek istemiyor',
};

export const segmentSesNames = {
	cyB61t9BfZ7I8yL3: 'A',
	LoqQY6Q8iH06rFQ9: 'B',
	eBPYffexR4VQHLoR: 'C1',
	'xO-J9UJY-BSybxlx': 'C2',
	kwxl26VE2sTsP2lH: 'D',
	'tXW1QTAmW20j-8LG': 'E',
};

export const educationNames = {
	'vGKjx9ArFOOYtc-S': 'İlkokul terk',
	'8xrhtARGhZ1sdvu0': 'İlkokul mezunu',
	'Zw6x5Lgda3W0yq-A': 'Ortaokul mezunu',
	'6b3pVnZmcUZdGuwQ': 'Lise mezunu',
	RC5JN1ffr6qeO9ao: 'Yüksekokul mezunu',
	i94Gu55nLIWvh1pE: 'Açıköğretim mezunu',
	'WdMPMcstVzRT2La-': 'Üniversite mezunu',
	lNNmHT5yJSJoRTZG: 'Master, doktora ve üzeri',
};

export const ageNames = {
	k2bxkf9rsnqEcPaR: '16-20',
	'J0L-MpnafHRlSBNJ': '21-24',
	YFIvuFCxn7WjSO48: '25-29',
	tF4n2GHOmM6ySoiR: '30-34',
	aESGAh7zkx8jXVrF: '35-39',
	'5mKYpO4aiVxOCng6': '40-44',
	'4sl4NcYfZoc-7cMn': '45-49',
	JUZsdq5yj1ZJInEK: '50-54',
	pwgpNQJP10Ljdu0E: '55+',
};

export const regionNames = {
	AJoecDeeqphDRGVs: 'Akdeniz',
	dZmTTu0maMndXApQ: 'Doğu Anadolu',
	KoUblWl4tpTGqbJm: 'Ege',
	IHYJeUsk2985swQ4: 'Güneydoğu Anadolu',
	'8dCKa9IsxWSFLFF8': 'İç Anadolu',
	Jw4Ln10mpdSsimwT: 'Karadeniz',
	'5A2PQMXkBXSjrnCx': 'Marmara',
};
