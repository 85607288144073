export const QUESTION_TYPES = {
	single_selection: {
		title: 'Single Selection',
		bgColor: 'bg-indigo-600',
		borderColor: 'border-indigo-600',
		textColor: 'text-indigo-600',
	},
	multiple_selection: {
		title: 'Multiple Selection',
		bgColor: 'bg-indigo-600',
		borderColor: 'border-indigo-600',
		textColor: 'text-indigo-600',
	},
	yes_no: {
		title: 'Yes/No',
		bgColor: 'bg-indigo-600',
		borderColor: 'border-indigo-600',
		textColor: 'text-indigo-600',
	},
	text: {
		title: 'Text',
		bgColor: 'bg-red-600',
		borderColor: 'border-red-600',
		textColor: 'text-red-600',
		ringColor: 'ring-red-500',
	},
	number: {
		title: 'Number',
		bgColor: 'bg-red-600',
		borderColor: 'border-red-600',
		textColor: 'text-red-600',
		ringColor: 'ring-red-500',
	},
	rating: {
		title: 'Rating',
		bgColor: 'bg-yellow-400',
		borderColor: 'border-yellow-400',
		textColor: 'text-yellow-600',
	},
	ranking: {
		title: 'Ranking',
		bgColor: 'bg-yellow-400',
		borderColor: 'border-yellow-400',
		textColor: 'text-yellow-600',
	},
	nps: {
		title: 'NPS®',
		bgColor: 'bg-yellow-400',
		borderColor: 'border-yellow-400',
		textColor: 'text-yellow-600',
	},
	opinion_scale: {
		title: 'Opinion Scale',
		bgColor: 'bg-yellow-400',
		borderColor: 'border-yellow-400',
		textColor: 'text-yellow-600',
	},
	matrix: {
		title: 'Matrix',
		bgColor: 'bg-green-700',
		borderColor: 'border-green-700',
		textColor: 'text-green-700',
	},
	file_upload: {
		title: 'File Upload',
		bgColor: 'bg-green-700',
		borderColor: 'border-green-700',
		textColor: 'text-green-700',
		ringColor: 'ring-green-600',
	},
	description: {
		title: 'Description',
		bgColor: 'bg-green-700',
		borderColor: 'border-green-700',
		textColor: 'text-green-700',
	},
};
