import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	promoCodesList: {
		list: [],
		error: '',
	},
	newPromoCode: {
		promoCode: {},
		error: '',
	},
	updatePromoCode: {
		error: '',
	},
	deletePromoCode: {
		error: '',
	},
};

export const promoCodesSlice = createSlice({
	name: 'promoCodes',
	initialState,
	reducers: {
		getPromoCodes: (state, action) => {
			state.promoCodesList.list = action.payload;
		},
		getPromoCodesFail: (state, action) => {
			state.promoCodesList.error = action.payload;
		},
		createPromoCode: (state, action) => {
			state.newPromoCode.promoCode = action.payload;
		},
		createPromoCodeFail: (state, action) => {
			state.newPromoCode.error = action.payload;
		},
		clearPromoCodeCreateErrors: state => {
			state.newPromoCode.error = '';
		},
		updatePromoCodeFail: (state, action) => {
			state.updatePromoCode.error = action.payload;
		},
	},
});

export const {
	getPromoCodes,
	getPromoCodesFail,
	createPromoCode,
	createPromoCodeFail,
	clearPromoCodeCreateErrors,
	updatePromoCodeFail,
} = promoCodesSlice.actions;

export default promoCodesSlice.reducer;
