import { object, string } from 'yup';
import { ERROR_MESSAGES } from '../../utils/error-messages';

const { required, valid_email } = ERROR_MESSAGES;

export const userCreateValidationSchema = object({
	first_name: string()
		.nullable()
		.required(ERROR_MESSAGES.required('First Name')),
	last_name: string().nullable().required(ERROR_MESSAGES.required('Last Name')),
	email: string().nullable().required(required('email')).email(valid_email()),
});
