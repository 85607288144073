// install (please make sure versions match peerDependencies)
// yarn add @nivo/core @nivo/bar
import { ResponsiveBar } from '@nivo/bar';

// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.
export default function Histogram({ data, binWidth, markedValue }) {
	const oneSecondInMs = 1000;
	const oneMinuteInMis = 60 * oneSecondInMs;
	const oneHourInMs = 60 * oneMinuteInMis;
	const oneDayInMs = 24 * oneHourInMs;

	const selectedBinIndex = data.findIndex(item => {
		return markedValue >= item.bin && markedValue <= item.bin + binWidth;
	});

	return (
		<ResponsiveBar
			data={data}
			keys={['total_time']}
			indexBy="bin"
			padding={0.1}
			colors={bar => (bar.index === selectedBinIndex ? '#4338ca' : '#e4e4e7')}
			colorBy="bin"
			labelSkipWidth={12}
			labelSkipHeight={12}
			enableGridY={false}
			enableLabel={false}
			role="application"
			margin={{ top: 5 }}
			axisBottom={null}
			axisLeft={null}
			tooltip={({ id, indexValue, value, color }) => {
				let valueToRender = 0;
				let unitToRender = '';

				if (indexValue < oneSecondInMs) {
					valueToRender = indexValue;
					unitToRender = 'ms';
				} else if (indexValue < oneMinuteInMis) {
					valueToRender = indexValue / oneSecondInMs;
					unitToRender = 's';
				} else if (indexValue < oneHourInMs) {
					valueToRender = indexValue / oneMinuteInMis;
					unitToRender = 'm';
				} else if (indexValue < oneDayInMs) {
					valueToRender = indexValue / oneHourInMs;
					unitToRender = 'h';
				}

				if (unitToRender !== 'ms') {
					valueToRender = valueToRender.toFixed(2);
				}

				return (
					<div className="p-1 text-xs bg-white shadow rounded flex gap-2">
						<div
							className="w-4 h-4 border rounded border-gray-400"
							style={{ backgroundColor: color }}
						></div>
						<div>
							<div>
								<strong>
									{id}: {valueToRender} {unitToRender}
								</strong>
							</div>
							<div>
								<strong>count: {value}</strong>
							</div>
						</div>
					</div>
				);
			}}
		/>
	);
}
