import { endPoints } from '../../endPoints';
import { sorbunuApi } from '../axios';

export async function getProjectResponses({
	projectNanoId,
	reviewStatus = null,
	nextUrl = null,
}) {
	let url = '';

	if (nextUrl) {
		url = nextUrl;
	} else {
		url = endPoints.responses.GET_PROJECT_RESPONSES(projectNanoId);

		if (reviewStatus !== null) {
			url += `&review_status=${reviewStatus}`;
		}
	}

	const response = await sorbunuApi.get(url);

	return response.data;
}

export async function retrieveResponse({ responseNanoId }) {
	const response = await sorbunuApi.get(
		endPoints.responses.RETRIEVE_RESPONSE(responseNanoId),
	);

	return response.data;
}
