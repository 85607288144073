import {
	CheckCircleIcon,
	ExclamationCircleIcon,
	QuestionMarkCircleIcon,
	XCircleIcon,
} from '@heroicons/react/24/outline';
import { classNames } from '../../utils';

export const notificationsMetaData = {
	info: {
		icon: QuestionMarkCircleIcon,
		label: 'Info',
		color: 'text-grey-600',
	},
	warning: {
		icon: ExclamationCircleIcon,
		label: 'Warning',
		color: 'text-amber-600',
	},
	error: {
		icon: XCircleIcon,
		label: 'Error',
		color: 'text-red-600',
	},
	success: {
		icon: CheckCircleIcon,
		label: 'Success',
		color: 'text-green-400',
	},
};

export default function NotificationIcon({ iconType, className }) {
	const CustomTag = notificationsMetaData[iconType].icon;
	return (
		<CustomTag
			className={classNames(className, notificationsMetaData[iconType].color)}
			aria-hidden="true"
		/>
	);
}
