export function classNames(...classes) {
	return classes.filter(Boolean).join(' ');
}

export function newChoiceTemplate({ title }) {
	return {
		title: title,
	};
}

export function scaleBase64Image(dataURL, maxWidth, maxHeight) {
	return new Promise(done => {
		var img = new Image();
		img.onload = () => {
			var scale, newWidth, newHeight, canvas, ctx;
			if (img.width > maxWidth) {
				scale = maxWidth / img.width;
			} else if (img.height > maxHeight) {
				scale = maxHeight / img.height;
			} else {
				scale = 1;
			}
			newWidth = img.width * scale;
			newHeight = img.height * scale;
			canvas = document.createElement('canvas');
			canvas.height = newHeight;
			canvas.width = newWidth;
			ctx = canvas.getContext('2d');

			ctx.drawImage(
				img,
				0,
				0,
				img.width,
				img.height,
				0,
				0,
				newWidth,
				newHeight,
			);
			done(canvas.toDataURL());
		};
		img.src = dataURL;
	});
}
