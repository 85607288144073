import moment from 'moment';

import { useSelector } from 'react-redux';

export default function CommentsTab() {
	const { comments } = useSelector(state => state.projects);

	return (
		<section aria-labelledby="comments-title">
			<div className="bg-white h-1/4 shadow sm:overflow-hidden sm:rounded-lg">
				<div className="divide-y divide-gray-200">
					<div className="px-4 py-5 sm:px-6 flex justify-between">
						<h2
							id="comments-title"
							className="flex text-lg font-medium text-gray-900"
						>
							Comments
						</h2>
					</div>
					<hr />
				</div>
				<div className="divide-y divide-gray-200">
					<div className="h-96 overflow-auto px-4 py-5 sm:px-6 mb-3">
						{comments.results && (
							<>
								{comments.results.map((item, index) => (
									<div key={index}>
										<div className="flex items-center mb-3">
											{item.user.profile_picture ? (
												<img className="bg-gray-300 rounded-full w-8 h-8" />
											) : (
												<div className="bg-indigo-300 rounded-full w-8 h-8 flex items-center justify-center text-white">
													{item.user.first_name?.charAt(0)?.toUpperCase() +
														item.user.last_name?.charAt(0)?.toUpperCase()}
												</div>
											)}

											<div className="ml-5 capitalize font-semibold">
												{item.user.first_name}
											</div>
											<p className="ml-5 text-xs">
												{moment(item.modified).format(
													'MMMM Do YYYY, h:mm:ss a',
												)}
											</p>
										</div>
										<p className="text-sm mb-3">{item.body}</p>
										<hr className="mb-5" />
									</div>
								))}
							</>
						)}
					</div>
					<hr />
				</div>
			</div>
		</section>
	);
}
