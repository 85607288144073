import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	usersList: {
		list: [],
		error: '',
		sorting: 'first_name',
		totalUserLength: null,
	},
	newUser: {
		user: {},
		error: {
			nonFieldErrors: '',
			errors: '',
			detail: '',
		},
	},
	deleteUser: {
		error: '',
	},
};

export const usersSlice = createSlice({
	name: 'users',
	initialState,
	reducers: {
		getUsers: (state, action) => {
			state.usersList.list = action.payload.results;
			state.usersList.totalUserLength = action.payload.count;
		},
		getUsersFail: (state, action) => {
			state.usersList.error = action.payload;
		},
		createUser: (state, action) => {
			state.newUser.user = action.payload;
		},
		createUserFail: (state, action) => {
			if (action.payload.non_field_errors) {
				state.newUser.error.nonFieldErrors = action.payload;
			} else if (action.payload.errors) {
				state.newUser.error.errors = action.payload;
			} else if (action.payload.detail) {
				state.newUser.error.detail = action.payload;
			}
		},
		clearUserCreateErrors: state => {
			state.newUser.error = {
				nonFieldErrors: '',
				errors: '',
				detail: '',
			};
		},
		deleteUser: state => {
			state.deleteUser.error = '';
		},
		deleteUserFail: (state, action) => {
			state.deleteUser.error = action.payload;
		},
		modifyUserFail: (state, action) => {
			state.deleteUser.error = action.payload;
		},
		changeSorting: (state, action) => {
			state.usersList.sorting = action.payload;
		},
	},
});

export const {
	getUsers,
	getUsersFail,
	createUser,
	createUserFail,
	clearUserCreateErrors,
	deleteUser,
	deleteUserFail,
	modifyUserFail,
	changeSorting,
} = usersSlice.actions;

export default usersSlice.reducer;
