import moment from 'moment';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { UserSort } from '../../components/Sorts/UserSort';
import Pagination from '../../components/Pagination/index';

export default function Users() {
	const Navigate = useNavigate();
	const dispatch = useDispatch();
	const userList = useSelector(state => state.users.usersList.list);
	const sorting = useSelector(state => state.users.usersList.sorting);
	const { page } = useParams();
	const totalUserLength = useSelector(
		state => state.users.usersList.totalUserLength,
	);

	const startIndex = (page - 1) * 10;
	const endIndex = startIndex + 10;

	useEffect(() => {
		dispatch({
			type: 'users/get_users',
			payload: {
				sorting,
				limit: 10,
				page,
			},
		});
	}, [dispatch, sorting, page]);

	const AddUserBtnHandler = () => {
		Navigate(`/users/create`);
	};

	const navigateDetailsHandler = nanoid => {
		Navigate(`/users/${nanoid}`);
	};

	const handlePageChange = currentPage => {
		Navigate(`/users/${currentPage}`);
	};

	return (
		<div className="px-4 sm:px-6 lg:px-8 mt-10">
			<div className="sm:flex sm:items-center">
				<div className="sm:flex-auto">
					<h1 className="text-xl font-semibold text-gray-900">Users</h1>
					<p className="mt-2 text-sm text-gray-700">
						A list of all the users in your account including their name,
						company, email and role.
					</p>
					<div className="flex items-center mt-8">
						<div className="mr-5">
							<UserSort sorting={sorting} />
						</div>
					</div>
				</div>
				<div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
					<button
						type="button"
						className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
						onClick={AddUserBtnHandler}
					>
						Add user
					</button>
				</div>
			</div>
			<div className="-mx-4 mt-8 overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:-mx-6 md:mx-0 md:rounded-lg">
				<table className="min-w-full divide-y divide-gray-300">
					<thead className="bg-gray-50">
						<tr>
							<th
								scope="col"
								className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
							>
								Name
							</th>
							<th
								scope="col"
								className=" px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
							>
								Company
							</th>
							<th
								scope="col"
								className=" px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
							>
								Email
							</th>
							<th
								scope="col"
								className=" px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
							>
								Date Joined
							</th>
							<th
								scope="col"
								className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
							>
								Role
							</th>
						</tr>
					</thead>
					<tbody className="divide-y divide-gray-200 bg-white">
						{userList.map(user => (
							<tr
								key={user.nanoid}
								onClick={() => navigateDetailsHandler(user.nanoid)}
							>
								<td className="w-full max-w-0 py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none sm:pl-6">
									{!user.first_name && !user.last_name && <>(empty)</>}
									{user.first_name} {user.last_name}
								</td>
								<td className=" px-3 py-4 text-sm text-gray-500 sm:table-cell">
									{!user.company && <>(empty)</>}
									{user.company?.name}
								</td>
								<td className=" px-3 py-4 text-sm text-gray-500 sm:table-cell">
									{user.email}
								</td>
								<td className=" px-3 py-4 text-sm text-gray-500 sm:table-cell">
									{moment(user.date_joined).format('MMMM Do YYYY, h:mm:ss a')}
								</td>
								<td className="px-3 py-4 text-sm text-gray-500">
									{user.is_staff ? 'Staff' : 'User'}
								</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
			<Pagination
				startIndex={startIndex + 1}
				endIndex={endIndex <= totalUserLength ? endIndex : totalUserLength}
				totalItemCount={totalUserLength}
				handlePageChange={handlePageChange}
				currentPage={+page}
				itemName="users"
			/>
		</div>
	);
}
