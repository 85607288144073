import React from 'react';
import ChoiceList from '../ChoiceList';

const YesNoQuestion = ({
	question,
	handleQuestionSubmit,
	handleChoiceImageChange,
	handleChoiceImageTooLarge,
	lastQuestion,
	response = null,
	showNextQuestionButton = true,
}) => {
	return (
		<ChoiceList
			question={question}
			canAdd={false}
			canDelete={false}
			canReorder={false}
			handleQuestionSubmit={handleQuestionSubmit}
			handleChoiceImageChange={handleChoiceImageChange}
			handleChoiceImageTooLarge={handleChoiceImageTooLarge}
			lastQuestion={lastQuestion}
			response={response}
			showNextQuestionButton={showNextQuestionButton}
		/>
	);
};

export default YesNoQuestion;
