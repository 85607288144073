import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import DeleteAccountApprove from '../../components/DeleteApprovalModal/DeleteAccountApprove';
import DeleteAccountAlert from '../../components/DeleteModal/DeleteAccountAlert';
import UserDetailsCard from './UserDetailsCard';

export default function UserDetails() {
	const userList = useSelector(state => state.users.usersList.list);
	const dispatch = useDispatch();
	const [deleteUserModalOn, setDeleteUserModalOn] = useState(false);
	const [deleteUserApproved, setDeleteUserApproved] = useState(false);

	const { nanoid } = useParams();
	const user = userList.find(user => user.nanoid === nanoid);

	const deleteBtnHandler = () => {
		setDeleteUserModalOn(true);
	};

	useEffect(() => {
		dispatch({
			type: 'users/get_users',
		});
	}, [dispatch]);

	return (
		<>
			<div className="min-h-full">
				{user && (
					<UserDetailsCard user={user} deleteBtnHandler={deleteBtnHandler} />
				)}
				{deleteUserModalOn && (
					<DeleteAccountAlert
						open={deleteUserModalOn}
						setOpen={setDeleteUserModalOn}
						approved={deleteUserApproved}
						setApproved={setDeleteUserApproved}
					/>
				)}
				{deleteUserApproved && (
					<DeleteAccountApprove
						open={deleteUserApproved}
						setOpen={setDeleteUserApproved}
						nanoid={nanoid}
					/>
				)}
			</div>
		</>
	);
}
