import moment from 'moment';
import { Formik, Form } from 'formik';
import { FormInput } from '../../components/TextInput';
import { useDispatch } from 'react-redux';
import { updateMarketingDetailsSchema } from './constant';
import NiceModal from '@ebay/nice-modal-react';
import Modal from '../../components/Modal';
import { useParams } from 'react-router-dom';

export default function OverviewTab({ project }) {
	const dispatch = useDispatch();
	const { nanoid } = useParams();

	const handleApproveButtonClick = () => {
		NiceModal.show(Modal, {
			title: 'Are you sure?',
			message:
				'By approving the project, you will open the survey to the respondents and have withdrawn the fee of the research from the provision.',
			showPrimaryButton: true,
			primaryButtonLabel: "I'm Sure",
			secondaryButtonLabel: 'Cancel',
			onPrimaryButtonClick: async () => {
				dispatch({
					type: 'projects_status_change',
					payload: {
						nanoid,
						status: 'approved',
					},
				});
			},
		});
	};

	const handlePauseButtonClick = () => {
		NiceModal.show(Modal, {
			title: 'Reason for stopping the project',
			message:
				'By pausing the project, the survey will have frozen the response collection process and no answers will be collected until it is reopened. Are you sure to continue?',
			showPrimaryButton: true,
			showTextArea: true,
			primaryButtonLabel: "I'm Sure",
			secondaryButtonLabel: 'Cancel',
			checkboxLabel: 'Notify the customer about status change',
			onPrimaryButtonClick: () => {
				dispatch({
					type: 'projects_status_change',
					payload: {
						nanoid,
						status: 'paused',
					},
				});
			},
		});
	};

	const handleCompleteButtonClick = () => {
		NiceModal.show(Modal, {
			title: 'Reason for completing the project',
			message:
				'By completing the project, the survey will stop and no answers will be collected until it is reopened. THIS ACTION CANNOT BE UNDONE. Are you sure to continue?',
			showPrimaryButton: true,
			showTextArea: true,
			primaryButtonLabel: "I'm Sure",
			secondaryButtonLabel: 'Cancel',
			checkboxLabel: 'Notify the customer about status change',
			onPrimaryButtonClick: () => {
				dispatch({
					type: 'projects_status_change',
					payload: {
						nanoid,
						status: 'completed',
					},
				});
			},
		});
	};

	const updateMarketingDetails = values => {
		dispatch({
			type: 'projects_marketing-details',
			payload: {
				nanoid,
				marketing_title: values.title,
				marketing_description: values.description,
			},
		});
	};

	const handleRerunProjectClick = () => {
		dispatch({
			type: 'projects_status_change',
			payload: {
				nanoid,
				status: 'running',
			},
		});
	};

	return (
		<div className="grid grid-cols-1 gap-6 lg:grid-flow-col-dense lg:grid-cols-3">
			<div className="space-y-6 lg:col-span-2 lg:col-start-1">
				<section aria-labelledby="project-information-title">
					<div className="bg-white shadow sm:rounded-lg">
						<div className="px-4 py-5 sm:px-6">
							<h2
								id="project-information-title"
								className="text-lg font-medium leading-6 text-gray-900"
							>
								Project Details
							</h2>
							<p className="mt-1 max-w-2xl text-sm text-gray-500">
								See project details and update title and description seen by
								Denebunu users.
							</p>
						</div>
						<div className="border-t border-gray-200 px-4 py-5 sm:px-6">
							<dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-3">
								<div className="sm:col-span-1">
									<dt className="text-sm font-medium text-black">
										Project Title
									</dt>
									<dd className="mt-1 text-sm text-gray-900 capitalize">
										{project.title}
									</dd>
								</div>
								<div className="sm:col-span-1">
									<dt className="text-sm font-medium text-black">
										Project Status
									</dt>
									<dd className="mt-1 text-sm text-gray-900">
										{project.status}
									</dd>
								</div>
								<div className="sm:col-span-1">
									<dt className="text-sm font-medium text-black">Created</dt>
									<dd className="mt-1 text-sm text-gray-900">
										{moment(project.created).format('MMMM Do YYYY, h:mm:ss a')}
									</dd>
								</div>
								<div className="sm:col-span-1">
									<dt className="text-sm font-medium text-black">Modified</dt>
									<dd className="mt-1 text-sm text-gray-900">
										{moment(project.modified).format('MMMM Do YYYY, h:mm:ss a')}
									</dd>
								</div>
								<div className="sm:col-span-2">
									<dt className="text-sm font-medium text-black">
										Country Code
									</dt>
									<dd className="mt-1 text-sm text-gray-900 capitalize">
										{project?.country_code}
									</dd>
								</div>
							</dl>
							<dt className="text-md font-medium text-black sm:col-span-3 mt-10">
								Customer
							</dt>
							<dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-3 mt-1">
								<div className="sm:col-span-1">
									<dt className="text-sm font-medium text-black">First Name</dt>
									<dd className="mt-1 text-sm text-gray-900">
										{project?.user?.first_name}
									</dd>
								</div>
								<div className="sm:col-span-1">
									<dt className="text-sm font-medium text-black">Last Name</dt>
									<dd className="mt-1 text-sm text-black">
										{project?.user?.last_name}
									</dd>
								</div>
								<div className="sm:col-span-1">
									<dt className="text-sm font-medium text-black">Email</dt>
									<dd className="mt-1 text-sm text-gray-900">
										{project?.user?.email}
									</dd>
								</div>
							</dl>
						</div>
						<hr />
						<div>
							<Formik
								initialValues={{
									title: project.marketing_title || '',
									description: project.marketing_description || '',
								}}
								enableReinitialize={true}
								validateOnChange={true}
								validationSchema={updateMarketingDetailsSchema}
								onSubmit={values => {
									updateMarketingDetails(values);
								}}
							>
								{props => (
									<div className="space-y-6 pt-8 sm:space-y-5 sm:pt-10 px-6">
										<Form>
											<div className="sm:grid sm:grid-cols-4 sm:gap-4 sm:pt-5">
												<label
													htmlFor="title"
													className="block text-sm font-medium sm:col-span-2 text-gray-900 sm:pt-3 "
												>
													Marketing Title
												</label>
												<div className="mt-2 sm:col-span-2 sm:mt-0">
													<FormInput
														id="title"
														name="title"
														type="text"
														className="block w-full text-sm max-w-lg rounded-md border-gray-500 py-1.5 text-gray-900 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 focus:outline-none "
													/>
												</div>
											</div>

											<div className="sm:grid sm:grid-cols-4 sm:items-start sm:gap-4  sm:pt-5">
												<label
													htmlFor="description"
													className="block text-sm font-medium sm:col-span-2 leading-6 text-gray-900 sm:pt-3"
												>
													Marketing Description
												</label>
												<div className="mt-2 sm:col-span-2 sm:mt-0 pb-2">
													<FormInput
														id="description"
														name="description"
														type="text"
														className="block w-full text-sm max-w-lg rounded-md border-gray-500 py-1.5 text-gray-900 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 focus:outline-none "
													/>
												</div>
											</div>
											<div className="text-right pb-3">
												<button
													type="submit"
													disabled={!props.isValid || !props.dirty}
													className="disabled:cursor-not-allowed disabled:hover:bg-indigo-600 disabled:opacity-30 inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
												>
													Save
												</button>
											</div>
										</Form>
									</div>
								)}
							</Formik>
						</div>
					</div>
				</section>
			</div>

			<section
				aria-labelledby="status-buttons"
				className="lg:col-span-1 lg:col-start-3"
			>
				<div className="bg-white px-4 py-5 shadow sm:rounded-lg sm:px-4">
					<div className="flex flex-col gap-2">
						<button
							onClick={() =>
								dispatch({
									type: 'projects_status_change',
									payload: {
										nanoid,
										status: 'under_edit',
									},
								})
							}
							className="bg-yellow-500 text-white text-xs shadow-sm rounded-lg w-full p-2 disabled:opacity-30 disabled:cursor-not-allowed focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500"
							disabled={
								!(project.marketing_description && project.marketing_title) ||
								project.status !== 'under_approval'
							}
						>
							Under Edit: Give Revision
						</button>

						<button
							onClick={handlePauseButtonClick}
							disabled={project.status !== 'running'}
							className="bg-orange-600 text-white text-xs shadow-sm rounded-lg w-full p-2 disabled:opacity-30 disabled:cursor-not-allowed focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500"
						>
							Pause the Project
						</button>

						<button
							disabled={project.status !== 'paused'}
							className="bg-green-600 text-white text-xs shadow-sm rounded-lg w-full p-2 disabled:opacity-30 disabled:cursor-not-allowed focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500"
							onClick={handleRerunProjectClick}
						>
							Re-run the Project
						</button>
						<button
							className="bg-green-600 text-white text-xs rounded-lg shadow-sm w-full p-2 disabled:opacity-30 disabled:cursor-not-allowed focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
							onClick={handleApproveButtonClick}
							disabled={
								!(project.marketing_description && project.marketing_title) ||
								project.status !== 'under_approval'
							}
						>
							Approve: Approve and Launch
						</button>

						<button
							disabled={
								project.status !== 'running' && project.status !== 'paused'
							}
							onClick={handleCompleteButtonClick}
							className="bg-red-600 text-white text-xs shadow-sm rounded-lg w-full p-2 disabled:opacity-30 disabled:cursor-not-allowed focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500"
						>
							Complete the Project
						</button>
					</div>
				</div>
			</section>
		</div>
	);
}
