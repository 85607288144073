import { Fragment, useRef, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import NiceModal, { useModal } from '@ebay/nice-modal-react';

const textAreaErrorMessage = 'This field cannot be left blank.';

function Modal({
	title,
	message,
	primaryButtonLabel,
	onPrimaryButtonClick,
	showPrimaryButton = true,
	showTextArea = false,
	secondaryButtonLabel,
	checkboxLabel,
	onSecondaryButtonClick,
}) {
	const [textAreaValue, setTextAreaValue] = useState('');
	const [showTextAreaError, setShowTextAreaError] = useState(false);
	const cancelButtonRef = useRef(null);
	const modal = useModal();

	const handlePrimaryButtonClick = () => {
		if (showTextArea && !textAreaValue) {
			setShowTextAreaError(true);
			return;
		}
		onPrimaryButtonClick();
		modal.hide();
	};

	const handleSecondaryButtonClick = () => {
		if (onSecondaryButtonClick) {
			onSecondaryButtonClick();
		}
		modal.hide();
	};

	return (
		<Transition.Root show={modal.visible} as={Fragment}>
			<Dialog
				as="div"
				className="relative z-10"
				initialFocus={cancelButtonRef}
				onClose={() => modal.hide()}
			>
				<Transition.Child
					as={Fragment}
					enter="ease-out duration-300"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="ease-in duration-200"
					leaveFrom="opacity-100"
					leaveTo="opacity-0"
				>
					<div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
				</Transition.Child>

				<div className="fixed inset-0 z-10 overflow-y-auto">
					<div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
							enterTo="opacity-100 translate-y-0 sm:scale-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100 translate-y-0 sm:scale-100"
							leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
						>
							<Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
								<div>
									<div className="mt-3 text-center sm:mt-5">
										{title && (
											<Dialog.Title
												as="h3"
												className="text-lg font-medium leading-6 text-gray-900"
											>
												{title}
											</Dialog.Title>
										)}
										{showTextArea && (
											<div className="mt-2">
												<textarea
													className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border-gray-300 rounded-md"
													rows="3"
													value={textAreaValue}
													onChange={e => setTextAreaValue(e.target.value)}
												/>
											</div>
										)}
										{showTextAreaError && (
											<div className="mt-2">
												<p className="text-sm text-red-500">
													{textAreaErrorMessage}
												</p>
											</div>
										)}
										{message && (
											<div className="mt-2">
												<p className="text-sm text-gray-500">{message}</p>
											</div>
										)}
									</div>
								</div>
								{checkboxLabel && (
									<div className="mt-5">
										<label className="inline-flex items-center">
											<input
												type="checkbox"
												className="form-checkbox h-4 w-4 text-indigo-600"
											/>
											<span className="ml-2 text-sm text-gray-500">
												{checkboxLabel}
											</span>
										</label>
									</div>
								)}
								<div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
									{showPrimaryButton && (
										<button
											type="button"
											className="inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-2 sm:text-sm"
											onClick={handlePrimaryButtonClick}
										>
											{primaryButtonLabel}
										</button>
									)}
									<button
										type="button"
										className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm"
										onClick={handleSecondaryButtonClick}
										ref={cancelButtonRef}
									>
										{secondaryButtonLabel}
									</button>
								</div>
							</Dialog.Panel>
						</Transition.Child>
					</div>
				</div>
			</Dialog>
		</Transition.Root>
	);
}

export default NiceModal.create(
	({
		title,
		message,
		primaryButtonLabel,
		onPrimaryButtonClick,
		showPrimaryButton,
		showTextArea,
		secondaryButtonLabel,
		checkboxLabel,
		onSecondaryButtonClick,
	}) => {
		return (
			<Modal
				title={title}
				message={message}
				primaryButtonLabel={primaryButtonLabel}
				onPrimaryButtonClick={onPrimaryButtonClick}
				showPrimaryButton={showPrimaryButton}
				showTextArea={showTextArea}
				secondaryButtonLabel={secondaryButtonLabel}
				checkboxLabel={checkboxLabel}
				onSecondaryButtonClick={onSecondaryButtonClick}
			/>
		);
	},
);
