import {
	signOut,
	failSignIn,
	stopSpin,
	startSpin,
	signIn,
	clearUserSignInErrors,
} from '../../store/slices/authSlice';
import { push } from 'redux-first-history';
import {
	AUTH_STORAGE_KEY,
	RESENT_EMAIL_KEY,
	saveToLocalStorage,
} from '../../utils';
import { DASHBOARD_PATH } from '../../routes';
import { endPoints } from '../../endPoints';
import { sorbunuApi } from '../../api/axios';
import { call, put, takeLatest } from 'redux-saga/effects';

function* userSignIn(action) {
	yield put(clearUserSignInErrors());
	yield put(startSpin());
	try {
		const response = yield call(() =>
			sorbunuApi.post(endPoints.auth.SIGN_IN(), action.payload),
		);
		saveToLocalStorage(AUTH_STORAGE_KEY, response.data);
		yield put(signIn(response.data.user));
		yield put(push(DASHBOARD_PATH));
	} catch (err) {
		yield put(failSignIn(err.response.data));
	} finally {
		yield put(stopSpin());
	}
}

function* userLogOut() {
	const res = yield call(() => sorbunuApi.post(endPoints.auth.LOG_OUT()));
	if (res) {
		localStorage.removeItem(AUTH_STORAGE_KEY);
		localStorage.removeItem(RESENT_EMAIL_KEY);
		return yield put(signOut());
	}
}

export function* authModuleSaga() {
	yield takeLatest('auth/sign_in', userSignIn);
	yield takeLatest('auth/log_out', userLogOut);
}
