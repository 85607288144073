export const AUTH_STORAGE_KEY = 'auth';
export const RESENT_EMAIL_KEY = 'isResent';

export function classNames(...classes) {
	return classes.filter(Boolean).join(' ');
}

export const saveToLocalStorage = (key, data) => {
	const savedData = typeof data === 'string' ? data : JSON.stringify(data);
	localStorage.setItem(key, savedData);
};

export const getItemFromLocalStorage = key => {
	const data = localStorage.getItem(key);

	return !data ? null : JSON.parse(data);
};
export const removeItemFromLocalStorage = key => {
	localStorage.removeItem(key);
};

export const isDateBigger = (date1, date2) => {
	return date1.getTime() > date2.getTime();
};

export function formatNumberAsThousands(num) {
	if (num < 1000) {
		return num.toString();
	} else if (num >= 1000 && num < 1000000) {
		return (num / 1000).toFixed(1) + 'K';
	} else if (num >= 1000000 && num < 1000000000) {
		return (num / 1000000).toFixed(1) + 'M';
	} else {
		return (num / 1000000000).toFixed(1) + 'B';
	}
}
