import { call, put, select, takeLatest, takeEvery } from 'redux-saga/effects';
import {
	getProjectResponses,
	retrieveResponse,
} from '../../api/Services/Response';
import {
	setProjectResponseReviewStatusCounts,
	setSelectedResponseQuestion,
	setSelectedResponse,
	finishProjectResponsesLoad,
	setProjectResponseDetails,
	finishProjectResponseReviewPost,
	setQuestionnaireMeasurementHistogram,
	setResponseMeasurement,
} from '../slices/projectsSlice';
import { postResponseReview } from '../../api/Services/ResponseReview';
import {
	getQuestionnaireHistogram,
	getResponseMeasurement,
} from '../../api/Services/Measurement';

function* getProjectResponsesSaga(action) {
	const response = yield call(() =>
		getProjectResponses({
			projectNanoId: action.payload.projectNanoId,
			reviewStatus: action.payload.filterBy,
			nextUrl: action.payload.nextUrl,
		}),
	);

	yield put(
		finishProjectResponsesLoad({
			projectNanoId: action.payload.projectNanoId,
			append: action.payload.append,
			results: response.results,
			nextUrl: response.next,
		}),
	);

	if (response.results.length > 0) {
		yield put(
			setSelectedResponse({
				projectNanoId: action.payload.projectNanoId,
				responseNanoId: response.results[0].nanoid,
			}),
		);
	}

	const responseMeasurementHistogram = yield call(() =>
		getQuestionnaireHistogram({
			questionnaireNanoId: action.payload.questionnaireNanoId,
		}),
	);

	yield put(
		setQuestionnaireMeasurementHistogram({
			questionnaireNanoId: action.payload.questionnaireNanoId,
			entries: responseMeasurementHistogram.entries,
			binWidth: responseMeasurementHistogram.bin_width,
		}),
	);

	yield put({
		type: 'project/startProjectResponseReviewStatusCountsUpdate',
		payload: {
			projectNanoId: action.payload.projectNanoId,
		},
	});
}

function* startProjectResponseReviewStatusCountsUpdateSaga(action) {
	const response = yield call(() =>
		getProjectResponses({
			projectNanoId: action.payload.projectNanoId,
		}),
	);

	const counts = {
		total: response.count,
		approved: 0,
		pending_review: 0,
		rejected: 0,
	};

	const approvedResponses = yield call(() =>
		getProjectResponses({
			projectNanoId: action.payload.projectNanoId,
			reviewStatus: 'approved',
		}),
	);
	const pendingReviewResponses = yield call(() =>
		getProjectResponses({
			projectNanoId: action.payload.projectNanoId,
			reviewStatus: 'pending_review',
		}),
	);
	const rejectedResponses = yield call(() =>
		getProjectResponses({
			projectNanoId: action.payload.projectNanoId,
			reviewStatus: 'rejected',
		}),
	);

	counts['approved'] = approvedResponses.count;
	counts['pending_review'] = pendingReviewResponses.count;
	counts['rejected'] = rejectedResponses.count;

	yield put(
		setProjectResponseReviewStatusCounts({
			projectNanoId: action.payload.projectNanoId,
			counts: counts,
		}),
	);
}

function* postProjectResponseReviewSaga(action) {
	const requestResponse = yield call(() =>
		postResponseReview({
			responseNanoId: action.payload.responseNanoId,
			reviewStatus: action.payload.reviewStatus,
			rejectionReason: action.payload.rejectionReason,
			questionNanoId: action.payload.questionNanoId,
		}),
	);

	//yield delay(1500);

	if (requestResponse.status) {
		yield put({
			type: 'project/startProjectResponseReviewStatusCountsUpdate',
			payload: {
				projectNanoId: action.payload.projectNanoId,
			},
		});

		const responseDetails = yield call(() =>
			retrieveResponse({ responseNanoId: action.payload.responseNanoId }),
		);

		yield put(
			setProjectResponseDetails({
				projectNanoId: action.payload.projectNanoId,
				nanoid: action.payload.responseNanoId,
				response: responseDetails,
			}),
		);

		yield put(
			finishProjectResponseReviewPost({
				responseNanoId: action.payload.responseNanoId,
			}),
		);

		yield put(
			setSelectedResponseQuestion({
				responseNanoId: action.payload.responseNanoId,
				selectedQuestionNanoId: '',
			}),
		);

		const nextResponseNanoId = yield select(
			state =>
				state.projects.projectDetails[action.payload.projectNanoId].responses
					.nextResponseNanoId,
		);

		const hasNextResponse =
			nextResponseNanoId !== undefined && nextResponseNanoId !== '';

		if (hasNextResponse) {
			yield put(
				setSelectedResponse({
					projectNanoId: action.payload.projectNanoId,
					responseNanoId: nextResponseNanoId,
				}),
			);
		}
	}
}

function* setSelectedResponseSaga(action) {
	const responseMeasurement = yield call(() =>
		getResponseMeasurement({
			responseNanoId: action.payload.responseNanoId,
		}),
	);

	yield put(
		setResponseMeasurement({
			responseNanoId: action.payload.responseNanoId,
			projectNanoId: action.payload.projectNanoId,
			responseMeasurement: responseMeasurement,
		}),
	);
}

export function* responsesModuleSaga() {
	yield takeEvery(
		[
			'projects/startProjectResponsesLoad',
			'projects/setProjectResponsesFilterBy',
		],
		getProjectResponsesSaga,
	);
	yield takeEvery(
		'projects/startProjectResponseReviewPost',
		postProjectResponseReviewSaga,
	);
	yield takeEvery('projects/setSelectedResponse', setSelectedResponseSaga);
	yield takeLatest(
		'project/startProjectResponseReviewStatusCountsUpdate',
		startProjectResponseReviewStatusCountsUpdateSaga,
	);
}
