import { object, string } from 'yup';
import { ERROR_MESSAGES } from '../../utils/error-messages';

const { required, valid_email } = ERROR_MESSAGES;

export const signInValidationSchema = object({
	email: string().nullable().required(required('email')).email(valid_email()),
	password: string()
		.nullable()
		.required(ERROR_MESSAGES.required('password'))
		.matches(
			/^(?=.*[A-Za-z])(?=.*\d)(?=.*[!@#$%^&*-.,/'])[A-Za-z\d!@#$%^&*-.,/']{9,}$/,
			{
				excludeEmptyString: true,
				message: ERROR_MESSAGES.passwordSignIn(),
			},
		),
});
