import { classNames } from '../../utils';

export default function Badge({ className, type, children }) {
	return (
		<>
			<span
				className={classNames(
					className,
					type === 'info' ? 'bg-gray-100 text-gray-800' : '',
					type === 'warning' ? 'bg-yellow-100 text-yellow-800' : '',
					type === 'error' ? 'bg-red-100 text-red-800' : '',
					type === 'success' ? 'bg-green-100 text-green-800' : '',
					type === 'complete' ? 'bg-indigo-100 text-indigo-800' : '',
					'inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium',
				)}
			>
				{children}
			</span>
		</>
	);
}
