import { Fragment } from 'react';
import { Formik, Form } from 'formik';
import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { useDispatch, useSelector } from 'react-redux';
import { FormInput } from '../TextInput';
import { Buttons } from '../Button';
import FormAlert from '../FormAlert';
import { RemoveAccountValues } from '../../utils/initialValues';
import { removeAccountValidationSchema } from '../../utils/validationSchemas';

export default function DeleteAccountApprove({ open, setOpen, nanoid }) {
	const errorMessages = useSelector(state => state.users.usersList.error);

	const dispatch = useDispatch();
	const removeAccountHandler = values => {
		dispatch({
			type: 'users/delete_user',
			payload: {
				nanoid: nanoid,
				values: values,
			},
		});
	};

	return (
		<>
			<Transition.Root show={open} as={Fragment}>
				<Dialog
					as="div"
					className="fixed z-10 inset-0 overflow-y-auto"
					onClose={setOpen}
				>
					<div
						className="flex min-h-screen text-center md:block md:px-2 lg:px-4"
						style={{ fontSize: 0 }}
					>
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0"
							enterTo="opacity-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100"
							leaveTo="opacity-0"
						>
							<Dialog.Overlay className="hidden fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity md:block" />
						</Transition.Child>

						<span
							className="hidden md:inline-block md:align-middle md:h-screen"
							aria-hidden="true"
						>
							&#8203;
						</span>
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
							enterTo="opacity-100 translate-y-0 md:scale-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100 translate-y-0 md:scale-100"
							leaveTo="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
						>
							<div className="relative inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
								<div className="relative flex items-center bg-white px-4 pt-14 pb-8 overflow-hidden shadow-2xl sm:px-6 sm:pt-8 md:p-6 lg:p-8">
									<button
										type="button"
										className="absolute top-4 right-4 text-gray-400 hover:text-gray-500 sm:top-8 sm:right-6 md:top-6 md:right-6 lg:top-8 lg:right-8"
										onClick={() => setOpen(false)}
									>
										<span className="sr-only">Close</span>
										<XMarkIcon className="h-6 w-6" aria-hidden="true" />
									</button>

									<div className="w-full mt-6">
										<div className="sm:col-span-8 lg:col-span-7">
											<span className="text-sm text-gray-500">
												In order to complete this users removal, please confirm
												the company name below.
											</span>

											<section
												aria-labelledby="information-heading"
												className="mt-5"
											>
												<Formik
													initialValues={RemoveAccountValues}
													onSubmit={values => {
														removeAccountHandler(values);
													}}
													validationSchema={removeAccountValidationSchema}
												>
													<div className="mt-6">
														<Form className="space-y-6">
															<div className="space-y-1">
																<FormInput
																	id="company_name"
																	name="company_name"
																	type="text"
																	placeholder="Enter company name..."
																/>
															</div>
															<div>
																<Buttons
																	className="mt-6 w-full border border-transparent rounded-md py-3 px-8 flex items-center justify-center text-base font-medium text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
																	type="submit"
																	fullWidth
																>
																	Remove My Account.
																</Buttons>
															</div>
														</Form>
													</div>
												</Formik>
											</section>
											<p className="mt-3 text-red-400 text-center">
												{errorMessages && (
													<FormAlert errorMessage={errorMessages} />
												)}
											</p>
										</div>
									</div>
								</div>
							</div>
						</Transition.Child>
					</div>
				</Dialog>
			</Transition.Root>
		</>
	);
}
