import * as Yup from 'yup';
import { ERROR_MESSAGES } from '../../../utils/error-messages';

export const promoCodeCreateValidationSchema = Yup.object().shape({
	name: Yup.string().required(ERROR_MESSAGES.required('Name')),
	code: Yup.string()
		.min(1, 'Too Short')
		.max(128, 'Too Long')
		.matches(/^[a-zA-Z0-9]+$/i, 'Only English characters and numbers')
		.required(ERROR_MESSAGES.required('Promo Code')),
	percentage: Yup.string().when('calculation_type', {
		is: calculation_type =>
			calculation_type === 'Percentage' ||
			calculation_type === 'Percentage With Limit',
		then: Yup.string().required(ERROR_MESSAGES.required('Percentage')),
		otherwise: Yup.string().nullable(),
	}),
	percentage_amount_limit: Yup.string().when('calculation_type', {
		is: calculation_type => calculation_type === 'Percentage With Limit',
		then: Yup.string().required(
			ERROR_MESSAGES.required('Percentage With Limit'),
		),
		otherwise: Yup.string().nullable(),
	}),
	direct_discount_amount: Yup.string().when('calculation_type', {
		is: calculation_type => calculation_type === 'Direct Amount',
		then: Yup.string().required(ERROR_MESSAGES.required('Direct Amount')),
		otherwise: Yup.string().nullable(),
	}),
	valid_until: Yup.date().required(ERROR_MESSAGES.required('Valid Until')),
	user: Yup.string().when('assign_type', {
		is: assign_type => assign_type === 'Personal',
		then: Yup.string().required(ERROR_MESSAGES.required('User')),
		otherwise: Yup.string().notRequired(),
	}),
});

export const assignTypes = ['General', 'Personal'];

export const calculationTypes = [
	'Percentage',
	'Percentage With Limit',
	'Direct Amount',
];

export const applyTypes = ['Auto Apply', 'Manual'];
