import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	statsData: { stats: [], error: '' },
	underApprovalProjectsData: { projects: [], error: '' },
	projectsWithoutActionData: { projects: [], error: '' },
	projectsNearingEstimatedTimeData: { projects: [], error: '' },
};

export const dashboardSlice = createSlice({
	name: 'dashboard',
	initialState,
	reducers: {
		getStats: (state, action) => {
			state.statsData.stats = action.payload;
		},
		getStatsFail: (state, action) => {
			state.statsData.error = action.payload;
		},
		getUnderApprovalProjects: (state, action) => {
			state.underApprovalProjectsData.projects = action.payload;
		},
		getUnderApprovalProjectsFail: (state, action) => {
			state.underApprovalProjectsData.error = action.payload;
		},
		getProjectsWithoutAction: (state, action) => {
			state.projectsWithoutActionData.projects = action.payload;
		},
		getProjectsWithoutActionFail: (state, action) => {
			state.projectsWithoutActionData.error = action.payload;
		},
		getProjectsNearingEstimatedTime: (state, action) => {
			state.projectsNearingEstimatedTimeData.projects = action.payload;
		},
		getProjectsNearingEstimatedTimeFail: (state, action) => {
			state.projectsNearingEstimatedTimeData.error = action.payload;
		},
	},
});

export const {
	getStats,
	getStatsFail,
	getUnderApprovalProjects,
	getUnderApprovalProjectsFail,
	getProjectsWithoutAction,
	getProjectsWithoutActionFail,
	getProjectsNearingEstimatedTime,
	getProjectsNearingEstimatedTimeFail,
} = dashboardSlice.actions;

export default dashboardSlice.reducer;
