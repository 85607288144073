import React from 'react';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

const PromoCodesList = ({ promoCodes }) => {
	const Navigate = useNavigate();

	return (
		<div className="-mx-4 mt-8 overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:-mx-6 md:mx-0 md:rounded-lg">
			<table className="min-w-full divide-y divide-gray-300">
				<thead className="bg-gray-50">
					<tr>
						<th
							scope="col"
							className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
						>
							Name
						</th>
						<th
							scope="col"
							className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
						>
							Code
						</th>
						<th
							scope="col"
							className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
						>
							Assign Type
						</th>
						<th
							scope="col"
							className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
						>
							Percentage
						</th>
						<th
							scope="col"
							className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
						>
							Max Limit
						</th>
						<th
							scope="col"
							className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
						>
							Direct Amount
						</th>
						<th
							scope="col"
							className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
						>
							Starting Date
						</th>
						<th
							scope="col"
							className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
						>
							End Date
						</th>
						<th
							scope="col"
							className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
						></th>
					</tr>
				</thead>
				<tbody className="divide-y divide-gray-200 bg-white">
					{promoCodes.map(promoCode => (
						<tr key={promoCode.nanoid}>
							<td className="w-full max-w-0 py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none sm:pl-6">
								{promoCode.name}
							</td>
							<td className="hidden px-3 py-4 text-sm text-gray-500 lg:table-cell">
								{promoCode.code}
							</td>
							<td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
								{promoCode.assign_type}
							</td>
							<td className="px-3 py-4 text-sm text-gray-500">
								{promoCode.percentage}
							</td>
							<td className="px-3 py-4 text-sm text-gray-500">
								{promoCode.percentage_amount_limit}
							</td>
							<td className="px-3 py-4 text-sm text-gray-500">
								{promoCode.direct_discount_amount}
							</td>
							<td className="px-3 py-4 text-sm text-gray-500">
								{moment(promoCode.valid_from).format('MMMM Do YYYY, h:mm:ss')}
							</td>
							<td className="px-3 py-4 text-sm text-gray-500">
								{moment(promoCode.valid_until).format('MMMM Do YYYY, h:mm:ss')}
							</td>
							<td className="whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
								<button
									onClick={() =>
										Navigate(`/promo-codes/edit/${promoCode.nanoid}`)
									}
									className="text-indigo-600 hover:text-indigo-900"
									disabled={promoCode.used_date ? true : false}
								>
									Edit
								</button>
							</td>
						</tr>
					))}
				</tbody>
			</table>
		</div>
	);
};

export default PromoCodesList;
