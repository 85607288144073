export function Logo(props) {
	return (
		<svg
			version="1.0"
			xmlns="http://www.w3.org/2000/svg"
			width="9511.000000pt"
			height="3164.000000pt"
			viewBox="0 0 9511.000000 3164.000000"
			preserveAspectRatio="xMidYMid meet"
			{...props}
		>
			<g
				transform="translate(0.000000,3164.000000) scale(0.100000,-0.100000)"
				fill={props.color ? props.color : '#4F46E5'}
				stroke="none"
			>
				<path
					d="M12968 26395 c-5 -5 -8 -2274 -8 -5044 l1 -5036 3310 -3775 c1821
					-2076 3318 -3776 3328 -3778 19 -3 203 161 440 393 1552 1521 2574 3527 2895
					5682 117 780 141 1618 70 2403 -183 2030 -981 3962 -2281 5522 -225 270 -354
					410 -642 698 -637 636 -1303 1148 -2073 1593 -1244 720 -2662 1172 -4083 1301
					-363 34 -941 58 -957 41z"
				/>
				<path
					d="M10065 25343 c-154 -7 -510 -35 -680 -54 -2610 -293 -4988 -1590
					-6660 -3634 -824 -1006 -1454 -2187 -1830 -3430 -599 -1977 -575 -4091 67
					-6055 752 -2300 2327 -4264 4423 -5512 2122 -1264 4665 -1696 7105 -1208 1470
					294 2850 912 4041 1807 211 159 442 344 549 441 52 47 54 51 39 68 -9 11
					-1500 1711 -3313 3779 l-3296 3760 0 5022 0 5023 -187 -2 c-104 -1 -219 -3
					-258 -5z m-1675 -6297 c0 -3447 2 -3909 15 -4013 53 -402 228 -802 481 -1098
					38 -44 1190 -1359 2561 -2923 1371 -1564 2493 -2846 2493 -2850 0 -5 -72 -41
					-161 -80 -734 -332 -1478 -541 -2274 -641 -576 -73 -1229 -80 -1815 -20 -2003
					203 -3868 1180 -5185 2713 -1020 1189 -1661 2627 -1854 4161 -71 564 -81 1235
					-25 1795 218 2207 1352 4215 3139 5558 738 554 1556 969 2450 1242 88 27 163
					49 168 50 4 0 7 -1753 7 -3894z"
				/>
				<path
					d="M54250 16760 l0 -6450 1145 0 1144 0 5 33 c3 17 17 268 32 557 14
					289 27 526 28 527 1 2 29 -39 62 -90 173 -269 443 -532 739 -718 419 -263 916
					-424 1515 -491 172 -19 668 -16 857 5 642 72 1202 257 1708 564 282 171 487
					334 735 582 181 181 307 331 447 531 400 571 666 1294 762 2070 32 254 41 420
					41 730 0 310 -9 476 -41 730 -109 881 -427 1670 -920 2285 -701 874 -1686
					1382 -2864 1476 -211 17 -643 6 -845 -21 -926 -123 -1646 -506 -2074 -1104
					l-56 -78 0 2656 0 2656 -1210 0 -1210 0 0 -6450z m4788 339 c927 -100 1640
					-716 1906 -1647 74 -256 107 -520 107 -837 0 -383 -44 -668 -151 -978 -286
					-831 -934 -1368 -1805 -1499 -138 -20 -551 -17 -692 5 -916 146 -1596 781
					-1833 1712 -71 276 -84 396 -84 750 -1 332 7 413 60 670 58 273 200 617 351
					845 282 426 667 722 1143 881 123 41 318 84 440 98 110 12 445 13 558 0z"
				/>
				<path
					d="M31250 19099 c-1026 -80 -1793 -378 -2307 -896 -347 -350 -556 -800
					-625 -1343 -17 -136 -17 -544 1 -670 126 -919 636 -1493 1708 -1919 341 -136
					621 -226 1288 -415 707 -200 900 -265 1140 -381 287 -139 449 -280 541 -471
					47 -97 64 -185 64 -329 0 -152 -18 -237 -74 -350 -142 -287 -453 -444 -964
					-486 -388 -32 -785 37 -1085 187 -365 184 -642 523 -793 972 -21 61 -43 112
					-48 112 -10 0 -2007 -560 -2013 -565 -2 -1 13 -64 31 -141 258 -1046 1005
					-1748 2204 -2073 722 -196 1668 -240 2482 -115 1681 258 2589 1180 2590 2629
					0 706 -216 1275 -654 1724 -245 250 -508 432 -890 616 -385 186 -813 330
					-1511 509 -817 210 -963 252 -1185 342 -223 91 -365 178 -476 295 -76 81 -119
					156 -144 255 -54 210 2 431 148 586 146 155 337 244 642 300 98 18 153 21 370
					21 277 0 381 -12 575 -68 113 -32 307 -126 403 -194 239 -170 445 -459 547
					-769 14 -45 20 -53 37 -49 11 3 427 97 926 208 499 111 911 206 916 210 10 9
					-15 127 -61 279 -345 1147 -1282 1818 -2758 1976 -177 19 -846 27 -1025 13z"
				/>
				<path
					d="M40780 19104 c-654 -46 -1207 -164 -1725 -369 -164 -65 -494 -227
					-640 -315 -451 -273 -852 -633 -1153 -1038 -297 -399 -522 -868 -662 -1381
					-181 -658 -223 -1427 -119 -2156 184 -1290 850 -2335 1902 -2981 278 -171 663
					-348 977 -449 1071 -344 2358 -360 3452 -44 1295 374 2265 1222 2736 2394 231
					575 342 1187 342 1878 -1 1191 -355 2213 -1036 2987 -729 829 -1773 1321
					-3094 1456 -147 15 -839 28 -980 18z m700 -1920 c702 -91 1277 -464 1635
					-1062 180 -300 297 -659 351 -1077 22 -165 25 -607 6 -775 -75 -647 -306
					-1164 -697 -1555 -338 -339 -750 -542 -1265 -622 -164 -26 -587 -25 -750 0
					-967 153 -1648 831 -1864 1854 -44 206 -57 336 -63 605 -10 437 39 781 159
					1127 47 137 163 382 237 500 372 599 975 961 1701 1021 134 11 402 3 550 -16z"
				/>
				<path
					d="M80735 19104 c-970 -77 -1750 -430 -2314 -1046 -29 -32 -54 -58 -57
					-58 -2 0 -4 212 -4 470 l0 470 -1210 0 -1210 0 0 -4315 0 -4315 1210 0 1210 0
					3 2518 c3 2304 5 2527 20 2641 55 403 163 717 334 976 90 136 282 328 418 418
					314 207 685 307 1142 307 402 0 699 -79 965 -256 333 -221 530 -589 577 -1078
					8 -75 11 -975 11 -2818 l0 -2708 1215 0 1215 0 0 2958 c0 1908 -4 3016 -10
					3122 -51 814 -328 1476 -806 1929 -450 426 -1062 679 -1854 767 -138 15 -731
					27 -855 18z"
				/>
				<path
					d="M52630 19039 c-1014 -59 -2044 -602 -2545 -1343 l-75 -110 0 677 0
					677 -1160 0 -1160 0 0 -4315 0 -4315 1218 2 1217 3 6 2310 c6 2550 1 2370 69
					2707 104 514 342 897 710 1143 394 263 895 383 1718 411 l202 7 0 1078 0 1079
					-32 -1 c-18 -1 -94 -5 -168 -10z"
				/>
				<path
					d="M65120 15973 c0 -2971 2 -3123 36 -3382 141 -1086 721 -1845 1684
					-2204 374 -140 780 -215 1276 -236 1118 -49 2075 281 2738 945 60 60 136 142
					170 183 33 41 62 68 63 60 1 -8 14 -237 28 -509 15 -272 28 -501 30 -507 4
					-10 242 -13 1150 -13 l1145 0 0 4315 0 4315 -1210 0 -1209 0 -4 -2517 c-3
					-2304 -5 -2529 -20 -2642 -103 -752 -405 -1230 -941 -1491 -347 -170 -839
					-236 -1276 -174 -494 71 -853 310 -1052 700 -93 181 -143 361 -167 603 -8 70
					-11 975 -11 2812 l0 2709 -1215 0 -1215 0 0 -2967z"
				/>
				<path
					d="M86433 15843 l3 -3098 22 -151 c119 -822 440 -1414 993 -1833 449
					-339 1039 -538 1784 -602 175 -14 608 -14 775 0 956 85 1750 462 2289 1086
					l89 103 6 -81 c4 -45 15 -246 26 -447 11 -201 23 -398 26 -437 l6 -73 1144 0
					1144 0 0 4315 0 4315 -1210 0 -1210 0 -3 -2542 c-3 -2771 1 -2583 -57 -2863
					-91 -445 -295 -808 -585 -1045 -243 -199 -564 -330 -915 -374 -641 -81 -1171
					55 -1500 384 -236 236 -369 559 -400 975 -6 92 -10 1055 -10 2803 l0 2662
					-1210 0 -1210 0 3 -3097z"
				/>
			</g>
		</svg>
	);
}
