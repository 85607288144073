import { t } from 'i18next';

export default function NextButton({ lastQuestion = false, disabled }) {
	return (
		<div className="mt-8 -mx-6 -my-4 px-6 py-4 bg-gray-100 text-right">
			<button
				disabled={disabled}
				type="submit"
				className="disabled:cursor-not-allowed disabled:hover:bg-denepink-600 disabled:opacity-30 items-center border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-denepink-600 hover:bg-denepink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-denepink-500"
			>
				<div className="inline-flex px-4 py-2">
					{lastQuestion ? t('Finish') : t('Next')}
				</div>
			</button>
		</div>
	);
}
