import {
	finishProjectsLoad,
	getProjectComments,
	finishProjectLoad,
	startQuestionnaireLoad,
	finishQuestionnaireLoad,
} from '../../store/slices/projectsSlice';
import { sorbunuApi } from '../../api/axios';
import { endPoints } from '../../endPoints';
import { call, put, takeLatest } from 'redux-saga/effects';
import { getAllProjects, getProject } from '../../api/Services/Projects';
import { getQuestionList } from '../../api/Services/Questions';

function* getAllProjectsSaga(action) {
	const response = yield call(() =>
		getAllProjects({
			status: action.payload.status,
			sorting: action.payload.sorting,
		}),
	);

	yield put(finishProjectsLoad({ results: response }));
}

function* getProjectSaga(action) {
	const response = yield call(() =>
		getProject({ projectNanoId: action.payload.projectNanoId }),
	);

	yield put(
		startQuestionnaireLoad({ questionnaireNanoId: response.questionnaire }),
	);

	yield put(
		finishProjectLoad({
			projectNanoId: action.payload.projectNanoId,
			projectData: response,
		}),
	);
}

function* getQuestionnaireSaga(action) {
	const response = yield call(() =>
		getQuestionList(action.payload.questionnaireNanoId),
	);

	yield put(
		finishQuestionnaireLoad({
			questionnaireNanoId: action.payload.questionnaireNanoId,
			questionList: response,
		}),
	);
}

function* projectsMarketingDetails(action) {
	const response = yield call(() =>
		sorbunuApi.patch(
			endPoints.projects.PROJECTS_MARKETING(action.payload.nanoid),
			{
				marketing_title: action.payload.marketing_title,
				marketing_description: action.payload.marketing_description,
			},
		),
	);
	yield put(
		finishProjectLoad({
			projectNanoId: action.payload.nanoid,
			projectData: response.data,
		}),
	);
}

function* getComments(action) {
	const response = yield call(() =>
		sorbunuApi.get(endPoints.projects.COMMENTS(action.payload)),
	);
	yield put(getProjectComments(response.data));
}

function* updateStatusChange(action) {
	const response = yield call(() =>
		sorbunuApi.patch(endPoints.projects.UPDATE_STATUS(action.payload.nanoid), {
			status: action.payload.status,
		}),
	);
	yield put(
		finishProjectLoad({
			projectNanoId: action.payload.projectNanoId,
			projectData: response.data,
		}),
	);
}

export function* projectsModuleSaga() {
	yield takeLatest('projects/startProjectsLoad', getAllProjectsSaga);
	yield takeLatest('projects/startProjectLoad', getProjectSaga);
	yield takeLatest('projects/startQuestionnaireLoad', getQuestionnaireSaga);
	yield takeLatest('projects_marketing-details', projectsMarketingDetails);
	yield takeLatest('projects_comments', getComments);
	yield takeLatest('projects_status_change', updateStatusChange);
}
