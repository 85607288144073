import {
	getPromoCodes,
	getPromoCodesFail,
	createPromoCode,
	createPromoCodeFail,
	clearPromoCodeCreateErrors,
	updatePromoCodeFail,
} from '../../store/slices/promoCodesSlice';
import { sorbunuApi } from '../../api/axios';
import { endPoints } from '../../endPoints';
import { call, put, takeLatest } from 'redux-saga/effects';
import { push } from 'redux-first-history';

function* fetchPromoCodes() {
	try {
		const response = yield call(() =>
			sorbunuApi.get(endPoints.promoCodes.GET_PROMO_CODES()),
		);

		const updatedPromoCodesArray = response.data.results.map(item => {
			for (let key in item) {
				if (
					key === 'assign_type' ||
					key === 'apply_type' ||
					key === 'calculation_type'
				) {
					item[key] = item[key]
						.split('_')
						.map(word => word.charAt(0).toUpperCase() + word.slice(1))
						.join(' ');
				}
			}
			return item;
		});

		yield put(getPromoCodes(updatedPromoCodesArray));
	} catch (err) {
		yield put(getPromoCodesFail(err.response.data));
	}
}

function* createNewPromoCode(action) {
	yield put(clearPromoCodeCreateErrors());
	try {
		const response = yield call(() =>
			sorbunuApi.post(endPoints.promoCodes.CREATE_PROMO_CODE(), action.payload),
		);
		yield put(createPromoCode(response.data));
		yield put(push('/promo-codes'));
	} catch (err) {
		yield put(createPromoCodeFail(err.response.data));
	}
}

function* updatePromoCode({ nanoid, payload }) {
	try {
		yield call(() =>
			sorbunuApi.patch(endPoints.promoCodes.UPDATE_PROMO_CODE(nanoid), payload),
		);
		yield put(push('/promo-codes'));
	} catch (err) {
		yield put(updatePromoCodeFail(err.response.data));
	}
}

export function* promoCodesModuleSaga() {
	yield takeLatest('promoCodes/get_promo_codes', fetchPromoCodes);
	yield takeLatest('promoCodes/create_new_promo_code', createNewPromoCode);
	yield takeLatest('promoCodes/update_promo_code', updatePromoCode);
}
