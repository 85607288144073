import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import PromoCodesList from './PromoCodesList';

const PromoCodes = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const promoCodes = useSelector(state => state.promoCodes.promoCodesList.list);

	const addPromoCodeBtnHandler = () => {
		navigate('/promo-codes/create');
	};

	useEffect(() => {
		dispatch({
			type: 'promoCodes/get_promo_codes',
		});
	}, [dispatch]);

	return (
		<div className="px-4 sm:px-6 lg:px-8 mt-10">
			<div className="sm:flex sm:items-center">
				<div className="sm:flex-auto">
					<h1 className="text-xl font-semibold text-gray-900">Promo Codes</h1>
					<p className="mt-2 text-sm text-gray-700">
						A list of all the promocodes in your account including their name,
						code, assign type, percentage, max limit, starting date and end
						date.
					</p>
					<div>
						<button
							type="button"
							className="mt-5 inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
							onClick={addPromoCodeBtnHandler}
						>
							Add Promo Code
						</button>
					</div>
				</div>
			</div>
			<PromoCodesList promoCodes={promoCodes} />
		</div>
	);
};

export default PromoCodes;
