import { Field } from 'formik';
import { ExclamationCircleIcon } from '@heroicons/react/24/solid';

export function TextInput({ type, id, name, label, autocomplete }) {
	return (
		<>
			<label
				htmlFor="last-name"
				className="block text-sm font-medium text-gray-700"
			>
				{label}
			</label>
			<input
				type={type}
				name={name}
				id={id}
				autoComplete={autocomplete}
				className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-700 focus:border-indigo-700 sm:text-sm"
			/>
		</>
	);
}

export const FormInput = ({
	name,
	placeholder,
	type,
	id,
	label,
	defaultValue,
	helperText = '',
	...inputProps
}) => {
	return (
		<Field name={name}>
			{({ field, meta }) => {
				const hasError = meta.touched && !!meta.error;
				const errorMessage = hasError ? meta.error : '';

				return (
					<div className="flex flex-col gap-1">
						<label
							htmlFor={id ?? ''}
							className="block text-sm font-medium text-gray-700"
						>
							{label}
						</label>
						{helperText && (
							<span className="text-xs font-light text-slate-400 mb-1">
								{helperText}
							</span>
						)}
						<div className="relative rounded-md shadow-sm">
							<input
								{...field}
								type={type}
								name={name}
								id={id}
								className={`block w-full pr-10 ${
									hasError
										? 'border-red-300 placeholder-red-300 focus:border-red-500 focus:ring-red-500 text-red-900'
										: 'border-gray-300 focus:border-indigo-500 focus:ring-indigo-500'
								} focus:outline-none  sm:text-sm rounded-md`}
								placeholder={placeholder}
								defaultValue={defaultValue}
								aria-invalid="true"
								{...inputProps}
							/>
							{hasError && (
								<div className="absolute inset-y-0  right-0 pr-3 flex items-center pointer-events-none">
									<ExclamationCircleIcon
										className="h-5 w-5 text-red-500"
										aria-hidden="true"
									/>
								</div>
							)}
						</div>
						{hasError && (
							<p className="mt-2 text-sm text-red-600" id="email-error">
								{errorMessage}
							</p>
						)}
					</div>
				);
			}}
		</Field>
	);
};
