import moment from 'moment';
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/solid';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Tabs from '../../components/Tabs';
import CommentsTab from './CommentsTab';
import OverviewTab from './OverviewTab';
import ModerationTab from './ModerationTab';
import { startProjectLoad } from '../../store/slices/projectsSlice';

export default function ProjectDetail() {
	const dispatch = useDispatch();
	const { nanoid, tab } = useParams();
	const { projectDetails } = useSelector(state => state.projects);
	const project = projectDetails[nanoid];
	const projectDetailPagePath = `/projects/${nanoid}`;

	let progressPerc = 0;
	if (project && project.metrics) {
		const totalReach = project.metrics.total_reach;
		const approvedCount = project.responses.counts.approved;
		progressPerc = (approvedCount / totalReach) * 100;
	}

	const tabs = [
		{
			name: 'Overview',
			href: `${projectDetailPagePath}/overview/`,
			current: tab === 'overview',
		},
		{
			name: 'Moderation',
			href: `${projectDetailPagePath}/moderation/`,
			current: tab === 'moderation',
		},
		{
			name: 'Comments',
			href: `${projectDetailPagePath}/comments/`,
			current: tab === 'comments',
		},
		{
			name: 'Billing',
			href: `${projectDetailPagePath}/billing/`,
			current: tab === 'billing',
		},
	];

	useEffect(() => {
		dispatch(startProjectLoad({ projectNanoId: nanoid }));
		dispatch({
			type: 'project/startProjectResponseReviewStatusCountsUpdate',
			payload: {
				projectNanoId: nanoid,
			},
		});

		dispatch({
			type: 'projects_comments',
			payload: nanoid,
		});
	}, [dispatch, nanoid]);

	if (!project) {
		return <></>;
	}

	return (
		<>
			<div>
				<main className="py-10">
					{/* Page header */}
					<div className="mx-auto px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 lg:px-8">
						<div className="flex items-center space-x-5">
							<div className="flex-shrink-0">
								<div className="relative">
									<div className="border-4 border-indigo-600 w-16 h-16 rounded-full flex items-center group">
										<span className="text-center w-full text-indigo-600 font-bold text-sm">
											%{progressPerc.toFixed(1)}
										</span>
									</div>
								</div>
							</div>
							<div>
								<h1 className="text-2xl font-bold text-gray-900">
									{project.title}
								</h1>
								<p className="text-sm font-medium text-gray-500">
									Created by{' '}
									<a
										href={`/users/${project?.user?.nanoid}`}
										className="text-gray-900"
									>
										{project?.user?.first_name} {project?.user?.last_name}
									</a>{' '}
									on{' '}
									<time
										title={moment(project.created).format(
											'MMMM Do YYYY, h:mm:ss a',
										)}
										dateTime={moment(project.created).format(
											'MMMM Do YYYY, h:mm:ss a',
										)}
									>
										{moment(project.created).format('MMMM Do YYYY')}
									</time>
									.
								</p>
							</div>
						</div>
						<div className="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-3 sm:space-y-0 sm:space-x-reverse md:mt-0 md:flex-row md:space-x-3">
							<a
								target="_blank"
								href={`${process.env.REACT_APP_PROJECT_DOMAIN_URL}/project/${project.nanoid}/audience`}
								rel="noreferrer"
								className="inline-flex items-center gap-x-1.5 justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
							>
								View in Sorbunu
								<ArrowTopRightOnSquareIcon className="-mr-0.5 w-5 h-5" />
							</a>
						</div>
					</div>
					<Tabs tabs={tabs} />
					<div className="mt-4 px-4 sm:px-6  max-w-3xl lg:max-w-full">
						{tab === 'overview' && <OverviewTab project={project} />}
						{tab === 'moderation' && <ModerationTab project={project} />}
						{tab === 'comments' && <CommentsTab />}
					</div>
				</main>
			</div>
		</>
	);
}
