const TEXTSIZE_CLASSES = {
	xSmall: 'text-xs',
	small: 'text-sm',
	medium: 'text-md',
	large: 'text-lg',
};

export default function Pagination({
	textSize,
	startIndex,
	endIndex,
	totalItemCount,
	itemName,
	currentPage,
	handlePageChange,
}) {
	return (
		<div
			className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6 my-4"
			aria-label="Pagination"
		>
			<div className="hidden sm:block">
				<p className={`${TEXTSIZE_CLASSES[textSize]}  text-gray-700`}>
					Showing {startIndex} to {endIndex} of {totalItemCount} {itemName}
				</p>
			</div>

			<div className="flex flex-1 justify-between sm:justify-end items-center">
				<button
					disabled={currentPage === 1}
					onClick={() => handlePageChange(currentPage - 1)}
					className={`relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 ${TEXTSIZE_CLASSES[textSize]} font-medium text-gray-700 hover:bg-gray-50 disabled:cursor-not-allowed disabled:opacity-30`}
				>
					Previous
				</button>
				<button
					disabled={endIndex >= totalItemCount}
					onClick={() => handlePageChange(currentPage + 1)}
					className={`relative ml-2 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 ${TEXTSIZE_CLASSES[textSize]} font-medium text-gray-700 hover:bg-gray-50 disabled:cursor-not-allowed disabled:opacity-30`}
				>
					Next
				</button>
			</div>
		</div>
	);
}
