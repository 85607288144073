import {
	CheckCircleIcon,
	ExclamationCircleIcon,
	InformationCircleIcon,
	XCircleIcon,
} from '@heroicons/react/24/solid';
import { classNames } from '../../utils';

function AlertIcon({ type, className }) {
	let CustomIcon = null;
	if (type === 'info') {
		CustomIcon = InformationCircleIcon;
	} else if (type === 'warning') {
		CustomIcon = ExclamationCircleIcon;
	} else if (type === 'success') {
		CustomIcon = CheckCircleIcon;
	} else if (type === 'error') {
		CustomIcon = XCircleIcon;
	}
	return <CustomIcon className={className} />;
}

export default function Alert({
	type,
	className,
	children,
	actionLabel,
	actionOnClick,
}) {
	return (
		<div
			className={classNames(
				className,
				type === 'info' ? 'bg-blue-50 border-blue-400' : '',
				type === 'warning' ? 'bg-yellow-50 border-yellow-400' : '',
				type === 'success' ? 'bg-green-50 border-green-400' : '',
				type === 'error' ? 'bg-red-50 border-red-400' : '',
				'border-l-4  p-4',
			)}
		>
			<div className="flex">
				<div className="flex-shrink-0">
					<AlertIcon
						type={type}
						className={classNames(
							type === 'info' ? 'text-blue-400' : '',
							type === 'warning' ? 'text-yellow-400' : '',
							type === 'success' ? 'text-green-400' : '',
							type === 'error' ? 'text-red-400' : '',
							'h-5 w-5',
						)}
					/>
				</div>
				<div className="ml-3">
					<div
						className={classNames(
							type === 'info' ? 'text-blue-700' : '',
							type === 'warning' ? 'text-yellow-700' : '',
							type === 'success' ? 'text-green-700' : '',
							type === 'error' ? 'text-red-700' : '',
							'text-sm',
						)}
					>
						{children}
					</div>
					{actionLabel && (
						<div className="mt-4">
							<div className="-mx-2 -my-1.5 flex">
								<button
									type="button"
									onClick={actionOnClick}
									className={classNames(
										type === 'info'
											? 'bg-blue-50 text-blue-800 hover:bg-blue-100 focus:ring-offset-blue-50 focus:ring-blue-600'
											: '',
										type === 'warning'
											? 'bg-yellow-50 text-yellow-800 hover:bg-yellow-100 focus:ring-offset-yellow-50 focus:ring-yellow-600'
											: '',
										type === 'success'
											? 'bg-green-50 text-green-800 hover:bg-green-100 focus:ring-offset-green-50 focus:ring-green-600'
											: '',
										type === 'error'
											? 'bg-red-50 text-red-800 hover:bg-red-100 focus:ring-offset-red-50 focus:ring-red-600'
											: '',
										'px-2 py-1.5 rounded-md text-sm font-medium focus:outline-none focus:ring-2 focus:ring-offset-2',
									)}
								>
									{actionLabel}
								</button>
							</div>
						</div>
					)}
				</div>
			</div>
		</div>
	);
}
