import { useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import { PromoCodeValues } from './initialValues';
import {
	promoCodeCreateValidationSchema,
	assignTypes,
	applyTypes,
	calculationTypes,
} from './constant';
import { FormInput } from '../../../components/TextInput';
import ComboboxWithoutNanoid from '../../../components/ComboboxWithoutNanoid';
import UserCombobox from '../../../components/UserCombobox';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
//Datepicker
import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker';
import parseISO from 'date-fns/parseISO';
import tr from 'date-fns/locale/tr';
import 'react-datepicker/dist/react-datepicker.css';

const CreatePromoCode = ({ mode }) => {
	const [initialValues, setInitialValues] = useState(PromoCodeValues);
	const { nanoid } = useParams();
	registerLocale('tr', tr);
	setDefaultLocale(tr);

	const dispatch = useDispatch();
	const userList = useSelector(state => state.users.usersList.list);
	const sorting = useSelector(state => state.users.usersList.sorting);
	const promoCodes = useSelector(state => state.promoCodes.promoCodesList.list);
	const currentPromoCode = promoCodes.find(
		promoCode => promoCode.nanoid === nanoid,
	);

	useEffect(() => {
		if (currentPromoCode) {
			setInitialValues({
				name: currentPromoCode.name,
				code: currentPromoCode.code,
				calculation_type: currentPromoCode.calculation_type,
				percentage: currentPromoCode.percentage,
				percentage_amount_limit: currentPromoCode.percentage_amount_limit,
				direct_discount_amount: currentPromoCode.direct_discount_amount,
				assign_type: currentPromoCode.assign_type,
				apply_type: currentPromoCode.apply_type,
				valid_from: parseISO(currentPromoCode.valid_from),
				valid_until: parseISO(currentPromoCode.valid_until),
				user: currentPromoCode?.user?.email || '',
			});
		} else {
			setInitialValues(PromoCodeValues);
		}
	}, [nanoid, setInitialValues, mode, promoCodes, currentPromoCode]);

	useEffect(() => {
		dispatch({
			type: 'users/get_users',
			payload: {
				sorting,
			},
		});
	}, [dispatch, sorting]);

	const formSubmissionHandler = values => {
		const updatedValues = {
			...values,
			code: values.code.toUpperCase(),
			assign_type: values.assign_type.toLowerCase(),
			calculation_type: values.calculation_type
				.toLowerCase()
				.replaceAll(' ', '_'),
			percentage_amount_limit: values.percentage_amount_limit
				? values.percentage_amount_limit.toLowerCase().replace(' ', '_')
				: '',
			apply_type: values.apply_type.toLowerCase().replace(' ', '_'),
			valid_from: values.valid_from,
			valid_until: values.valid_until,
			user: values.user
				? userList.find(item => item.email === values.user).nanoid
				: '',
		};

		for (const key in updatedValues) {
			if (!updatedValues[key]) {
				delete updatedValues[key];
			}
		}

		mode === 'edit'
			? dispatch({
					type: 'promoCodes/update_promo_code',
					nanoid: currentPromoCode.nanoid,
					payload: updatedValues,
			  })
			: dispatch({
					type: 'promoCodes/create_new_promo_code',
					payload: updatedValues,
			  });
	};

	return (
		<div className="bg-white mx-8 p-8 shadow-md rounded-lg sm:px-6 lg:px-8 mt-10">
			<div className="space-y-8 divide-y divide-gray-200">
				<div className="space-y-8 divide-y divide-gray-200">
					<Formik
						initialValues={initialValues}
						onSubmit={values => {
							formSubmissionHandler(values);
						}}
						enableReinitialize={true}
						validateOnChange={true}
						validationSchema={promoCodeCreateValidationSchema}
					>
						{props => (
							<Form>
								<>
									{mode === 'create' && (
										<>
											<h3 className="text-lg font-medium leading-6 text-gray-900">
												New Promo Code
											</h3>
											<p className="mt-1 text-sm text-gray-500">
												Please fill out the form completely.
											</p>
										</>
									)}
									{mode === 'edit' && (
										<h3 className="text-lg font-medium leading-6 text-gray-900">
											Edit Promo Code
										</h3>
									)}
									<div className="mt-6">
										<div className="lg:w-full xl:w-1/2 mb-4">
											<label
												htmlFor="promo-code-name"
												className="block text-sm font-medium text-gray-700"
											>
												Promo Code Name*
											</label>
											<div className="mt-1">
												<FormInput
													id="promo-code-name"
													type="text"
													name="name"
													className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
													value={props.values.name}
													onChange={props.handleChange}
												/>
											</div>
										</div>

										<div className="lg:w-full xl:w-1/2 mb-4">
											<label
												htmlFor="promo-code"
												className="block text-sm font-medium text-gray-700"
											>
												Promo Code*
											</label>
											<div className="mt-1">
												<FormInput
													id="promo-code"
													type="text"
													name="code"
													className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
													value={props.values.code.toUpperCase()}
													onChange={props.handleChange}
												/>
											</div>
										</div>

										<div className="lg:w-full xl:w-1/2 mb-4">
											<ComboboxWithoutNanoid
												data={calculationTypes}
												label="Calculation Type"
												selectedItem={props.values.calculation_type}
												onChange={async item => {
													props.setFieldValue('calculation_type', item);
													props.setFieldValue('percentage', '');
													props.setFieldValue('percentage_amount_limit', '');
													props.setFieldValue('direct_discount_amount', '');
												}}
											/>
										</div>

										{(props.values.calculation_type ===
											'Percentage With Limit' ||
											props.values.calculation_type === 'Percentage') && (
											<div className="lg:w-full xl:w-1/2 mb-4">
												<label
													htmlFor="percentage"
													className="block text-sm font-medium text-gray-700"
												>
													Percentage*
												</label>
												<div className="mt-1">
													<FormInput
														id="percentage"
														type="text"
														name="percentage"
														className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
														value={props.values.percentage}
														onChange={e => {
															const { value } = e.target;
															const regex = /^[^A-Za-z]*$/;
															if (regex.test(value) || value === '') {
																if (value > 100) {
																	props.setFieldValue('percentage', 100);
																	return;
																}
																props.setFieldValue('percentage', value);
															}
														}}
													/>
												</div>
											</div>
										)}

										{props.values.calculation_type ===
											'Percentage With Limit' && (
											<div className="lg:w-full xl:w-1/2 mb-4">
												<label
													htmlFor="percentage_amount_limit"
													className="block text-sm font-medium text-gray-700"
												>
													Percentage With Limit*
												</label>
												<div className="mt-1">
													<FormInput
														id="percentage_amount_limit"
														type="text"
														name="percentage_amount_limit"
														className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
														value={props.values.percentage_amount_limit}
														onChange={e => {
															const { value } = e.target;
															const regex = /^[^A-Za-z]*$/;
															if (regex.test(value) || value === '') {
																props.setFieldValue(
																	'percentage_amount_limit',
																	value,
																);
															}
														}}
													/>
												</div>
											</div>
										)}

										{props.values.calculation_type === 'Direct Amount' && (
											<div className="lg:w-full xl:w-1/2 mb-4">
												<label
													htmlFor="direct_discount_amount"
													className="block text-sm font-medium text-gray-700"
												>
													Direct Amount*
												</label>
												<div className="mt-1">
													<FormInput
														id="direct_discount_amount"
														type="text"
														name="direct_discount_amount"
														className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
														value={props.values.direct_discount_amount}
														onChange={e => {
															const { value } = e.target;
															const regex = /^[^A-Za-z]*$/;
															if (regex.test(value) || value === '') {
																props.setFieldValue(
																	'direct_discount_amount',
																	value,
																);
															}
														}}
													/>
												</div>
											</div>
										)}

										<div className="lg:w-full xl:w-1/2 mb-4">
											<ComboboxWithoutNanoid
												data={assignTypes}
												label="Assign Type*"
												selectedItem={props.values.assign_type}
												onChange={async item => {
													props.setFieldValue('user', '');
													props.setFieldValue('assign_type', item);
												}}
											/>
										</div>

										{props.values.assign_type === 'Personal' && (
											<div className="lg:w-full xl:w-1/2 mb-4">
												<UserCombobox
													data={userList}
													label="User*"
													selectedItem={props.values.user}
													isNanoid="true"
													onChange={async item => {
														props.setFieldValue('user', item);
													}}
												/>
											</div>
										)}

										<div className="lg:w-full xl:w-1/2 mb-4">
											<ComboboxWithoutNanoid
												data={applyTypes}
												label="Apply Type*"
												selectedItem={props.values.apply_type}
												onChange={async item =>
													props.setFieldValue('apply_type', item)
												}
											/>
										</div>

										<div className="lg:w-full xl:w-1/2 mb-4">
											<label
												htmlFor="valid_from"
												className="block text-sm font-medium text-gray-700"
											>
												Valid Until*
											</label>
											<div className="mt-2">
												<DatePicker
													id="valid_from"
													dateFormat="dd/MM/yyyy"
													locale="tr"
													className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
													selected={props.values.valid_from}
													onChange={async date => {
														props.setFieldValue('valid_from', date);
													}}
												/>
											</div>
										</div>

										<div className="lg:w-full xl:w-1/2 mb-4">
											<label
												htmlFor="valid_until"
												className="block text-sm font-medium text-gray-700"
											>
												Valid Until*
											</label>
											<div className="mt-2">
												<DatePicker
													id="valid_until"
													dateFormat="dd/MM/yyyy"
													name="valid_until"
													locale="tr"
													className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
													selected={props.values.valid_until}
													onChange={async date => {
														props.setFieldValue('valid_until', date);
													}}
												/>
												{props.errors.valid_until && props.touched.valid_until && (
													<p
														className="mt-2 text-sm text-red-600"
														id="valid_until-error"
													>
														{props.errors.valid_until}
													</p>
												)}
											</div>
										</div>
									</div>
									<div className="mt-6">
										<button
											type="submit"
											disabled={!props.isValid}
											className="disabled:cursor-not-allowed disabled:hover:bg-indigo-600 disabled:opacity-30 inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
										>
											{mode === 'edit' ? 'Save Changes' : 'Save'}
										</button>
									</div>
								</>
							</Form>
						)}
					</Formik>
				</div>
			</div>
		</div>
	);
};

export default CreatePromoCode;
