import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	ArrowSmallDownIcon,
	ArrowSmallUpIcon,
} from '@heroicons/react/24/solid';
import ProjectList from './ProjectList';

function classNames(...classes) {
	return classes.filter(Boolean).join(' ');
}

const Dashboard = () => {
	const dispatch = useDispatch();
	const { stats } = useSelector(state => state.dashboard.statsData);
	const underApprovalProjects = useSelector(
		state => state.dashboard.underApprovalProjectsData.projects,
	);
	const projectsWithoutActionData = useSelector(
		state => state.dashboard.projectsWithoutActionData.projects,
	);
	const projectsNearingEstimatedTimeData = useSelector(
		state => state.dashboard.projectsNearingEstimatedTimeData.projects,
	);

	useEffect(() => {
		dispatch({ type: 'dashboard/fetch_stats' });
		dispatch({ type: 'dashboard/fetch_under_approval_projects' });
		dispatch({ type: 'dashboard/fetch_projects_without_action' });
		dispatch({ type: 'dashboard/fetch_projects_nearing_estimated_time' });
	}, [dispatch]);

	return (
		<div className="m-10">
			<h3 className="text-lg leading-6 font-medium text-gray-900">Dashboard</h3>
			<dl className="mt-5 grid grid-cols-1 rounded-lg bg-white overflow-hidden shadow divide-y divide-gray-200 md:grid-cols-3 md:divide-y-0 md:divide-x">
				{stats.map(item => (
					<div key={item.title} className="px-4 py-5 sm:p-6">
						<dt className="text-base font-normal text-gray-900">
							{item.title}
						</dt>
						<dd className="mt-1 flex justify-between items-baseline md:block lg:flex">
							<div className="flex items-baseline text-2xl font-semibold text-indigo-600">
								{item.current_value}
								<span className="ml-2 text-sm font-medium text-gray-500">
									from {item.from_value}
								</span>
							</div>
							<div
								className={classNames(
									item.direction === 'positive'
										? 'bg-green-100 text-green-800'
										: 'bg-red-100 text-red-800',
									'inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium md:mt-2 lg:mt-0',
								)}
							>
								{item.direction === 'positive' ? (
									<ArrowSmallUpIcon
										className="-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-green-500"
										aria-hidden="true"
									/>
								) : (
									<ArrowSmallDownIcon
										className="-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-red-500"
										aria-hidden="true"
									/>
								)}

								<span className="sr-only">
									{item.direction === 'positive' ? 'positive' : 'negative'} by
								</span>
								{item.difference}
							</div>
						</dd>
					</div>
				))}
			</dl>
			<div className="mt-20">
				<ProjectList
					title="Projects Under Approval"
					projects={underApprovalProjects}
				/>
				<ProjectList
					title="Projects Under Edit with No Action"
					projects={projectsWithoutActionData}
				/>
				<ProjectList
					title="Projects with Less than 12 Hours to Estimated Completion Time"
					projects={projectsNearingEstimatedTimeData}
				/>
			</div>
		</div>
	);
};

export default Dashboard;
