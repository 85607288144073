import axios from 'axios';
import {
	AUTH_STORAGE_KEY,
	getItemFromLocalStorage,
	saveToLocalStorage,
} from '../utils';
import { endPoints } from '../endPoints';

export const sorbunuApi = axios.create({
	baseURL: process.env.REACT_APP_API_BASE_URL,
	headers: {
		'Content-Type': 'application/json',
	},
});

sorbunuApi.interceptors.request.use(
	config => {
		const auth = getItemFromLocalStorage(AUTH_STORAGE_KEY);
		const { access_token } = auth ?? {};
		if (access_token) {
			config.headers['Authorization'] = 'Bearer ' + access_token;
		}
		return config;
	},
	error => {
		return Promise.reject(error);
	},
);
sorbunuApi.interceptors.response.use(
	res => {
		return res;
	},
	async err => {
		const originalConfig = err.config;
		if (err.response) {
			// Access Token was expired
			if (err.response.status === 401 && !originalConfig._retry) {
				originalConfig._retry = true;
				try {
					const refresh_token =
						getItemFromLocalStorage(AUTH_STORAGE_KEY)?.refresh_token;
					if (refresh_token) {
						const authData = await axios.post(
							endPoints.auth.REFRESH_TOKEN(),
							{
								refresh: refresh_token,
							},
							{
								baseURL: process.env.REACT_APP_API_BASE_URL,
							},
						);

						const access_token = authData.data.access;
						const getItem = getItemFromLocalStorage(AUTH_STORAGE_KEY);
						saveToLocalStorage(AUTH_STORAGE_KEY, {
							...getItem,
							access_token,
						});
						sorbunuApi.defaults.headers.common['Authorization'] =
							'Bearer' + access_token;
					}

					return sorbunuApi(originalConfig);
				} catch (_error) {
					localStorage.clear();
					history.push('/sign-in');
					if (_error.response && _error.response.data) {
						return Promise.reject(_error.response.data);
					}
					return Promise.reject(_error);
				}
			}
			if (err.response.status === 403 && err.response.data) {
				return Promise.reject(err.response.data);
			}
		}
		return Promise.reject(err);
	},
);
