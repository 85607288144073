import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	auth: null,
	error: {
		nonFieldErrors: '',
		errors: '',
		detail: '',
	},
	isLoggedIn: false,
	spin: false,
};

export const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		signIn: (state, action) => {
			state.auth = action.payload;
			state.isLoggedIn = true;
		},
		failSignIn: (state, action) => {
			if (action.payload.non_field_errors) {
				state.error.nonFieldErrors = action.payload;
			} else if (action.payload.errors) {
				state.error.errors = action.payload;
			} else if (action.payload.detail) {
				state.error.detail = action.payload;
			}
		},
		clearUserSignInErrors: state => {
			state.error = {
				nonFieldErrors: '',
				errors: '',
				detail: '',
			};
		},
		signOut: state => {
			state.auth = null;
			state.isLoggedIn = false;
		},
		startSpin: state => {
			state.spin = true;
		},
		stopSpin: state => {
			state.spin = false;
		},
	},
});

export const {
	signInSuccess,
	signIn,
	failSignIn,
	clearUserSignInErrors,
	signOut,
	startSpin,
	stopSpin,
} = authSlice.actions;

export default authSlice.reducer;
