import {
	createUser,
	createUserFail,
	getUsers,
	getUsersFail,
	deleteUserFail,
	deleteUser,
	modifyUserFail,
	clearUserCreateErrors,
} from '../../store/slices/usersSlice';
import { sorbunuApi } from '../../api/axios';
import { endPoints } from '../../endPoints';
import { call, put, takeLatest } from 'redux-saga/effects';
import { push } from 'redux-first-history';

function* getUsersSaga(action) {
	try {
		const response = yield call(() =>
			sorbunuApi.get(
				endPoints.users.GET_USERS(
					action.payload.sorting,
					action.payload.limit,
					action.payload.page,
				),
			),
		);

		yield put(getUsers(response.data));
	} catch (err) {
		if (err.response.status === 400) {
			if (err.response.data.errors) {
				yield put(getUsersFail(err.response.data.errors));
			} else {
				yield put(getUsersFail(err.response.data.non_field_errors));
			}
		} else {
			yield put(getUsersFail(err.response.data.details));
		}
	}
}
function* createNewUserSaga(action) {
	yield put(clearUserCreateErrors());
	try {
		const response = yield call(() =>
			sorbunuApi.post(endPoints.users.CREATE_USER(), action.payload),
		);
		yield put(createUser(response.data));
		yield put(push(`/users/${response.data.nanoid}`));
	} catch (err) {
		yield put(createUserFail(err.response.data));
	}
}
function* deleteUserSaga(action) {
	try {
		yield call(
			() =>
				sorbunuApi.patch(
					endPoints.users.DELETE_USER(action.payload.nanoid),
					action.payload.values,
				),
			yield put(deleteUser()),
			yield put(push(`/users/`)),
		);
	} catch (err) {
		if (err.response.status === 400) {
			if (err.response.data.errors) {
				yield put(deleteUserFail(err.response.data.errors));
			} else {
				yield put(deleteUserFail(err.response.data.non_field_errors));
			}
		} else {
			yield put(deleteUserFail(err.response.data.detail));
		}
	}
}
function* deactivateUserSaga(action) {
	try {
		yield call(() =>
			sorbunuApi.patch(
				endPoints.users.MODIFY_USER(action.payload.nanoid),
				action.payload.values,
			),
		);
	} catch (err) {
		if (err.response.status === 400) {
			if (err.response.data.errors) {
				yield put(modifyUserFail(err.response.data.errors));
			} else {
				yield put(modifyUserFail(err.response.data.non_field_errors));
			}
		} else {
			yield put(modifyUserFail(err.response.data.detail));
		}
	}
}

export function* usersModuleSaga() {
	yield takeLatest('users/get_users', getUsersSaga);
	yield takeLatest('users/create_new_users', createNewUserSaga);
	yield takeLatest('users/delete_user', deleteUserSaga);
	yield takeLatest('users/deactivate_user', deactivateUserSaga);
}
