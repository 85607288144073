import { useState, useEffect } from 'react';
import { Combobox as HeadlessCombobox } from '@headlessui/react';
import { ChevronUpDownIcon, CheckIcon } from '@heroicons/react/24/outline';
import { classNames } from '../../utils';

const Combobox = ({
	data,
	label,
	disabled = false,
	selectedItem,
	onChange,
}) => {
	const [selectedData, setSelectedData] = useState(null);
	const [query, setQuery] = useState('');

	const handleChange = item => {
		onChange(item);
		setQuery('');
	};

	const filteredData =
		query === ''
			? data
			: data.filter(data => {
					return data.toLowerCase().includes(query.toLowerCase());
			  });

	useEffect(() => {
		if (selectedItem) {
			setSelectedData(selectedItem);
		}
	}, [selectedItem]);

	return (
		<HeadlessCombobox
			as="div"
			value={selectedData}
			onChange={handleChange}
			disabled={disabled}
		>
			<HeadlessCombobox.Label className="block text-sm font-medium text-gray-700">
				{label}
			</HeadlessCombobox.Label>
			<div className="relative mt-2">
				<HeadlessCombobox.Input
					className="w-full rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm"
					onChange={event => setQuery(event.target.value)}
					displayValue={item => item}
				/>
				<HeadlessCombobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
					<ChevronUpDownIcon
						className="h-5 w-5 text-gray-400"
						aria-hidden="true"
					/>
				</HeadlessCombobox.Button>
				{filteredData && filteredData.length > 0 && (
					<HeadlessCombobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
						{filteredData.map((item, index) => (
							<HeadlessCombobox.Option
								key={item + index}
								value={item}
								className={({ active }) =>
									classNames(
										'relative cursor-default select-none py-2 pl-3 pr-9',
										active ? 'bg-indigo-600 text-white' : 'text-gray-900',
									)
								}
							>
								{({ active, selected }) => (
									<>
										<span
											className={classNames(
												'block truncate',
												selected && 'font-semibold',
											)}
										>
											{item}
										</span>

										{selected && (
											<span
												className={classNames(
													'absolute inset-y-0 right-0 flex items-center pr-4',
													active ? 'text-white' : 'text-indigo-600',
												)}
											>
												<CheckIcon className="h-5 w-5" aria-hidden="true" />
											</span>
										)}
									</>
								)}
							</HeadlessCombobox.Option>
						))}
					</HeadlessCombobox.Options>
				)}
			</div>
		</HeadlessCombobox>
	);
};

export default Combobox;
