import {
	Routes,
	Route,
	Navigate,
	Outlet,
	useLocation,
	useNavigate,
} from 'react-router-dom';
import SignInPage from './pages/SignIn';
import SignedInLayout from './pages/SignedInLayout';
import { signIn } from './store/slices/authSlice';
import { AUTH_STORAGE_KEY, getItemFromLocalStorage } from './utils';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { DASHBOARD_PATH } from './routes';

const ProtectedRoute = ({
	isLoggedIn,
	redirectPath = '/sign-in',
	children,
}) => {
	if (!isLoggedIn) {
		return <Navigate to={redirectPath} replace />;
	}

	return children ? children : <Outlet />;
};

function App() {
	const isLoggedIn =
		useSelector(state => state.auth.isLoggedIn) ||
		!!getItemFromLocalStorage(AUTH_STORAGE_KEY)?.access_token;

	const authData = useSelector(state => state.auth.auth);
	const dispatch = useDispatch();
	const location = useLocation();
	const navigate = useNavigate();

	useEffect(() => {
		let auth;

		if (!authData && isLoggedIn) {
			auth = getItemFromLocalStorage(AUTH_STORAGE_KEY);
		}

		if (auth) {
			dispatch(signIn(auth));
		}
	}, [authData, dispatch, isLoggedIn]);

	useEffect(() => {
		if (location.pathname === '/') {
			navigate(DASHBOARD_PATH);
		}
	}, [location.pathname, navigate]);

	return (
		<div>
			<Routes>
				<Route path="/sign-in" element={<SignInPage />} />
				<Route element={<ProtectedRoute isLoggedIn={isLoggedIn} />}>
					<Route path="/*" element={<SignedInLayout />} />
				</Route>
			</Routes>
		</div>
	);
}

export default App;
