import { endPoints } from '../../endPoints';
import { sorbunuApi } from '../axios';

export async function getAllProjects({ status, sorting }) {
	const response = await sorbunuApi.get(
		endPoints.projects.GET_PROJECTS(status, sorting),
	);

	return response.data.results;
}

export async function getProject({ projectNanoId }) {
	const response = await sorbunuApi.get(
		endPoints.projects.RETRIEVE_PROJECT(projectNanoId),
	);

	return response.data;
}
