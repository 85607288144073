import { useDispatch, useSelector } from 'react-redux';
import { Formik, Form } from 'formik';
import { FormInput } from '../../components/TextInput';
import { signInValidationSchema } from './constant';
import { Buttons } from '../../components/Button';
import { SpinnerOverlay } from '../../components/Spinner';
import { useEffect, useState } from 'react';
import Notification from '../../components/Notification';
import { ExclamationCircleIcon } from '@heroicons/react/24/outline';
import FormAlert from '../../components/FormAlert';
import { Logo } from '../../components/Logo';

export default function SignInPage() {
	const errorMessages = useSelector(state => state.auth.error);
	const dispatch = useDispatch();
	const [showErrorNotifications, setShowErrorNotifications] = useState(false);
	const submitSignIn = values => {
		dispatch({ type: 'auth/sign_in', payload: values });
	};

	const SignInValues = {
		email: '',
		password: '',
	};

	const spin = useSelector(state => state.auth.spin);

	useEffect(() => {
		errorMessages.detail && setShowErrorNotifications(true);
		setTimeout(() => {
			setShowErrorNotifications(false);
		}, 3000);
	}, [errorMessages.detail]);

	return (
		<>
			<div className="bg-white min-h-screen flex">
				<div className="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
					<div className="mx-auto w-full max-w-sm lg:w-96 relative">
						<div>
							<Logo className="h-12 w-auto " />
							<h2 className="mt-6 text-3xl font-extrabold text-gray-900">
								Sign in to Your Back-office
							</h2>
						</div>
						{errorMessages.nonFieldErrors && (
							<FormAlert
								errorMessage={errorMessages.nonFieldErrors.non_field_errors}
							/>
						)}
						<div className="mt-8">
							<Formik
								initialValues={SignInValues}
								onSubmit={values => {
									submitSignIn(values);
								}}
								validateOnChange={false}
								validateOnBlur={false}
								validationSchema={signInValidationSchema}
							>
								<div className="mt-6">
									<Form className="space-y-6">
										<div className="space-y-1">
											<FormInput
												id="email"
												name="email"
												type="text"
												label="Email Address"
												placeholder="john@doe.com"
											/>
										</div>
										{errorMessages.errors &&
											errorMessages.errors.errors.email &&
											errorMessages.errors.errors.email.map((error, ind) => (
												<div
													key={ind}
													className="flex mt-1 items-center pointer-events-none"
												>
													<ExclamationCircleIcon
														className="h-5 w-5 text-red-500"
														aria-hidden="true"
													/>
													<p
														className="text-sm ml-2 text-red-600"
														id="email-error"
													>
														{error}
													</p>
												</div>
											))}
										<div className="space-y-1">
											<FormInput
												id="password"
												name="password"
												type="password"
												label="Password"
												placeholder="Enter your password..."
											/>
										</div>
										{errorMessages.errors &&
											errorMessages.errors.errors.password &&
											errorMessages.errors.errors.password.map((error, ind) => (
												<div
													key={ind}
													className="flex mt-1 items-center pointer-events-none"
												>
													<ExclamationCircleIcon
														className="h-5 w-5 text-red-500"
														aria-hidden="true"
													/>
													<p
														className="text-sm ml-2 text-red-600"
														id="email-error"
													>
														{error}
													</p>
												</div>
											))}
										<div>
											<Buttons type="submit" fullWidth>
												Sign In
											</Buttons>
										</div>
									</Form>
								</div>
							</Formik>
						</div>
						<SpinnerOverlay spin={spin} />
					</div>
				</div>

				<div className="hidden lg:block relative w-0 flex-1">
					<img
						className="absolute inset-0 h-full w-full object-cover"
						src="/backoffice.jpg"
						alt="img"
					/>
				</div>
				<Notification
					show={showErrorNotifications}
					setShow={setShowErrorNotifications}
					message={errorMessages && errorMessages.detail.detail}
					type={errorMessages ? 'error' : 'success'}
				/>
			</div>
		</>
	);
}
