import { classNames } from '../../utils';
import './spinner.css';

const spinnerWidths = {
	small: 'w-6 h-6',
	medium: 'w-12 h-12',
	large: 'w-24 h-24',
};

export function SpinnerOverlay({ spin, size = 'medium' }) {
	const spinnerWidthClass = spinnerWidths[size];
	if (spin) {
		return (
			<div className="absolute bg-white opacity-75 rounded-sm inset-0 flex items-center justify-center pointer-events-none ">
				<div
					className={`spinner z-10 opacity-100 ${spinnerWidthClass}`}
					role="status"
				/>
			</div>
		);
	} else return null;
}

export function Spinner({ className }) {
	return (
		<svg
			className={classNames('animate-spin', className)}
			xmlns="http://www.w3.org/2000/svg"
			fill="none"
			viewBox="0 0 24 24"
		>
			<circle
				className="opacity-25"
				cx="12"
				cy="12"
				r="10"
				stroke="currentColor"
				strokeWidth="4"
			></circle>
			<path
				className="opacity-75"
				fill="currentColor"
				d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
			></path>
		</svg>
	);
}
