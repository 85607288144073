import { assignTypes, calculationTypes, applyTypes } from './constant';

export const PromoCodeValues = {
	name: '',
	code: '',
	calculation_type: calculationTypes[0],
	percentage: '',
	percentage_amount_limit: '',
	direct_discount_amount: '',
	assign_type: assignTypes[0],
	apply_type: applyTypes[0],
	valid_from: new Date(),
	valid_until: '',
	user: '',
};
