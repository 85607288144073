import { endPoints } from '../../endPoints';
import { sorbunuApi } from '../axios';

export async function getQuestionnaireHistogram({ questionnaireNanoId }) {
	const response = await sorbunuApi.get(
		endPoints.responseMeasurements.GET_QUESTIONNAIRE_HISTOGRAM(
			questionnaireNanoId,
		),
	);

	return response.data;
}

export async function getResponseMeasurement({ responseNanoId }) {
	const response = await sorbunuApi.get(
		endPoints.responseMeasurements.GET_RESPONSE_MEASUREMENT(responseNanoId),
	);

	if (response.data.count > 0) {
		return response.data.results[0];
	}

	return null;
}

export async function getQuestionHistogram({ questionNanoId }) {
	const response = await sorbunuApi.get(
		endPoints.questionMeasurements.GET_QUESTION_HISTOGRAM(questionNanoId),
	);

	return response.data;
}

export async function getQuestionMeasurement({
	questionNanoId,
	responseNanoId,
}) {
	const response = await sorbunuApi.get(
		endPoints.questionMeasurements.GET_QUESTION_MEASUREMENT(
			questionNanoId,
			responseNanoId,
		),
	);

	if (response.data.count > 0) {
		return response.data.results[0];
	}

	return null;
}
