import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	loadingStatus: 'idle',
	projectsList: [],

	/*
	{
		...,
		responses: {
			status: 'idle',
			nextUrl: null,
			counts: {
				total: 0,
				pending_review: 0,
				approved: 0,
				rejected: 0 
			},
			responseList: [],
			responseDetails: {},
			responseMeasurements: {},

			selectedResponseNanoId: '',
			nextResponseNanoId: '',
			previousResponseNanoId: '',

			filterBy: 'pending_review'

		}
	}
	*/
	projectDetails: {},
	questionnaireDetails: {}, // questionnaireNanoId => list of questions
	questionnaireMeasurementHistogram: {}, // questionnaireNanoId => { entries: [], binWidth: 0 }

	responseMeta: {}, // responseNanoId: { status: 'idle', selectedQuestionNanoId: '' }

	status: 'all',
	currentPage: 1,
	sorting: '-created',
	comments: {},
};

export const projectsSlice = createSlice({
	name: 'projects',
	initialState,
	reducers: {
		startProjectsLoad: state => {
			state.loadingStatus = 'loading';
		},
		finishProjectsLoad: (state, action) => {
			state.loadingStatus = 'idle';
			state.projectsList = action.payload.results;
			state.projectsList.forEach(project => {
				state.projectDetails[project.nanoid] = {
					loadingStatus: 'idle',
					responses: {
						status: 'idle',
					},
				};
			});
		},

		startProjectLoad: (state, action) => {
			if (!state.projectDetails[action.payload.projectNanoId]) {
				state.projectDetails[action.payload.projectNanoId] = {
					nanoid: action.payload.projectNanoId,
					loadingStatus: 'idle',
					responses: {
						status: 'idle',
						nextUrl: null,
						responseList: [],
						responseDetails: {},
						responseMeasurements: {},

						selectedResponseNanoId: '',
						nextResponseNanoId: '',
						previousResponseNanoId: '',
						counts: {
							total: 0,
							approved: 0,
							pending_review: 0,
							rejected: 0,
						},

						filterBy: 'pending_review',
					},
				};
			}

			state.projectDetails[action.payload.projectNanoId].loadingStatus =
				'loading';
		},
		finishProjectLoad: (state, action) => {
			state.projectDetails[action.payload.projectNanoId] = {
				...action.payload.projectData,
				loadingStatus: 'idle',
				responses: {
					...state.projectDetails[action.payload.projectNanoId].responses,
					nextUrl: null,
					responseList: [],
					responseDetails: {},
					responseMeasurements: {},

					selectedResponseNanoId: '',
					nextResponseNanoId: '',
					previousResponseNanoId: '',
					counts: {
						total: 0,
						approved: 0,
						pending_review: 0,
						rejected: 0,
					},

					filterBy: 'pending_review',
				},
			};
		},
		setProjectResponseReviewStatusCounts: (state, action) => {
			state.projectDetails[action.payload.projectNanoId].responses.counts = {
				total: action.payload.counts.total,
				approved: action.payload.counts.approved,
				pending_review: action.payload.counts.pending_review,
				rejected: action.payload.counts.rejected,
			};
		},

		startQuestionnaireLoad: (state, action) => {
			state.questionnaireDetails[action.payload.questionnaireNanoId] = {
				...state.questionnaireDetails[action.payload.questionnaireNanoId],
				status: 'loading',
			};
		},
		finishQuestionnaireLoad: (state, action) => {
			state.questionnaireDetails[action.payload.questionnaireNanoId] = {
				questionList: action.payload.questionList,
				status: 'idle',
			};
		},

		startProjectResponsesLoad: (state, action) => {
			state.projectDetails[action.payload.projectNanoId].responses.status =
				'loading';
		},
		finishProjectResponsesLoad: (state, action) => {
			state.projectDetails[action.payload.projectNanoId].responses.status =
				'idle';

			if (action.payload.append) {
				state.projectDetails[
					action.payload.projectNanoId
				].responses.responseList.push(...action.payload.results);
			} else {
				state.projectDetails[
					action.payload.projectNanoId
				].responses.responseList = action.payload.results;
			}

			state.projectDetails[action.payload.projectNanoId].responses.nextUrl =
				action.payload.nextUrl;

			action.payload.results.forEach(response => {
				state.projectDetails[
					action.payload.projectNanoId
				].responses.responseDetails[response.nanoid] = response;

				state.responseMeta[response.nanoid] = {
					status: 'idle',
					selectedQuestionNanoId: '',
				};
			});
		},
		setQuestionnaireMeasurementHistogram: (state, action) => {
			state.questionnaireMeasurementHistogram[
				action.payload.questionnaireNanoId
			] = {
				entries: action.payload.entries,
				binWidth: action.payload.binWidth,
			};
		},
		setResponseMeasurement: (state, action) => {
			state.projectDetails[
				action.payload.projectNanoId
			].responses.responseMeasurements[action.payload.responseNanoId] =
				action.payload.responseMeasurement;
		},
		setSelectedResponseQuestion: (state, action) => {
			if (
				state.responseMeta[action.payload.responseNanoId]
					.selectedQuestionNanoId === action.payload.selectedQuestionNanoId
			) {
				state.responseMeta[
					action.payload.responseNanoId
				].selectedQuestionNanoId = '';
			} else {
				state.responseMeta[
					action.payload.responseNanoId
				].selectedQuestionNanoId = action.payload.selectedQuestionNanoId;
			}
		},
		setSelectedResponse: (state, action) => {
			state.projectDetails[
				action.payload.projectNanoId
			].responses.selectedResponseNanoId = action.payload.responseNanoId;

			const foundIndex = state.projectDetails[
				action.payload.projectNanoId
			].responses.responseList.findIndex(
				response => response.nanoid === action.payload.responseNanoId,
			);

			if (
				foundIndex ===
				state.projectDetails[action.payload.projectNanoId].responses
					.responseList.length -
					1
			) {
				state.projectDetails[
					action.payload.projectNanoId
				].responses.nextResponseNanoId = '';
			} else {
				state.projectDetails[
					action.payload.projectNanoId
				].responses.nextResponseNanoId =
					state.projectDetails[
						action.payload.projectNanoId
					].responses.responseList[foundIndex + 1].nanoid;
			}

			if (foundIndex === 0) {
				state.projectDetails[
					action.payload.projectNanoId
				].responses.previousResponseNanoId = '';
			} else {
				state.projectDetails[
					action.payload.projectNanoId
				].responses.previousResponseNanoId =
					state.projectDetails[
						action.payload.projectNanoId
					].responses.responseList[foundIndex - 1].nanoid;
			}
		},

		setProjectResponsesFilterBy: (state, action) => {
			state.projectDetails[action.payload.projectNanoId].responses.filterBy =
				action.payload.filterBy;
		},

		startProjectResponseReviewPost: (state, action) => {
			state.responseMeta[action.payload.responseNanoId].status = 'loading';
		},
		finishProjectResponseReviewPost: (state, action) => {
			state.responseMeta[action.payload.responseNanoId].status = 'idle';
		},

		setProjectResponseDetails: (state, action) => {
			state.projectDetails[
				action.payload.projectNanoId
			].responses.responseDetails[action.payload.nanoid] =
				action.payload.response;
		},

		changeStatus: (state, action) => {
			state.status = action.payload;
		},
		changeSorting: (state, action) => {
			state.sorting = action.payload;
		},

		getProjectComments: (state, action) => {
			state.comments = {
				...action.payload,
				results: [
					...(state.comments.results || []),
					...(action.payload.results || []),
				],
			};
		},
		setComments: state => {
			state.comments = {};
		},
	},
});

export const {
	startProjectsLoad,
	finishProjectsLoad,

	changeStatus,
	changeSorting,

	getProjectComments,
	setComments,

	startProjectLoad,
	finishProjectLoad,

	setProjectResponseReviewStatusCounts,

	startQuestionnaireLoad,
	finishQuestionnaireLoad,

	startProjectResponsesLoad,
	finishProjectResponsesLoad,

	setQuestionnaireMeasurementHistogram,
	setResponseMeasurement,

	setSelectedResponseQuestion,
	setSelectedResponse,
	setProjectResponsesFilterBy,

	setProjectResponseDetails,
	startProjectResponseReviewPost,
	finishProjectResponseReviewPost,
} = projectsSlice.actions;

export default projectsSlice.reducer;
