import { endPoints } from '../../endPoints';
import { sorbunuApi } from '../axios';

export async function postResponseReview({
	responseNanoId,
	reviewStatus,
	rejectionReason,
	questionNanoId,
}) {
	const requestBody = {
		response: responseNanoId,
		review_status: reviewStatus,
		rejection_reason: rejectionReason,
		question: questionNanoId,
	};
	const result = await sorbunuApi.post(
		endPoints.responseReviews.POST_RESPONSE_REVIEW(),
		requestBody,
	);
	return { status: true, data: result.data };
}
