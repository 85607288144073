import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { HistoryRouter as Router } from 'redux-first-history/rr6';
import store, { history } from './store';
import { Provider } from 'react-redux';
import NiceModal from '@ebay/nice-modal-react';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<React.StrictMode>
		<Provider store={store}>
			<Router history={history}>
				<NiceModal.Provider>
					<App />
				</NiceModal.Provider>
			</Router>
		</Provider>
	</React.StrictMode>,
);
