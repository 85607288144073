import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ProjectSort, PROJECT_STATUS_MAPPING } from '../../components/Sorts';
import Badge from '../../components/Badge';
import {
	ProjectFilterStatus,
	PROJECT_STATUS_TEXT_MAPPING,
} from '../../components/Filters';
import moment from 'moment';
import { startProjectsLoad } from '../../store/slices/projectsSlice';

export default function Projects() {
	const dispatch = useDispatch();
	const Navigate = useNavigate();

	const { status, sorting, projectsList } = useSelector(
		state => state.projects,
	);

	useEffect(() => {
		dispatch(
			startProjectsLoad({
				status: status,
				sorting: sorting,
			}),
		);
	}, [dispatch, status, sorting]);

	return (
		<>
			<div>
				<div className="px-4 sm:px-6 lg:px-12 mt-10">
					<div className="sm:flex sm:items-center">
						<div className="sm:flex-auto">
							<h1 className="text-xl font-semibold text-gray-900">Projects</h1>
							<p className="mt-2 text-sm text-gray-700">
								A list of all the projects created by Sorbunu users. Feel free
								to use the sorting and filtering feature to find what you are
								looking for.
							</p>
							<div className="flex items-center mt-8">
								<div className="mr-5">
									<ProjectSort sorting={sorting} />
								</div>
								<div className="mr-5">
									<ProjectFilterStatus status={status} />
								</div>
							</div>
						</div>
					</div>
					<div className="mt-8 flex flex-col">
						<div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
							<div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
								<div className="relative overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
									<table className="min-w-full table-fixed divide-y divide-gray-300">
										<thead className="bg-gray-50">
											<tr>
												<th
													scope="col"
													className="min-w-[12rem] py-3.5 pr-3 pl-6 text-left text-sm font-semibold text-gray-900"
												>
													Project Title
												</th>
												<th
													scope="col"
													className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
												>
													Status
												</th>
												<th
													scope="col"
													className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
												>
													Created
												</th>
												<th
													scope="col"
													className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
												>
													Company
												</th>
												<th
													scope="col"
													className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
												>
													User
												</th>
												<th
													scope="col"
													className="relative py-3.5 pl-3 pr-4 sm:pr-6"
												>
													<span className="sr-only">Edit</span>
												</th>
											</tr>
										</thead>
										<tbody className="divide-y divide-gray-200 bg-white">
											{projectsList.map(project => (
												<tr key={project.nanoid}>
													<td className="pl-6">{project.title}</td>
													<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
														<Badge
															type={PROJECT_STATUS_MAPPING[project.status]}
														>
															{project.status}
														</Badge>
													</td>
													<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
														{moment(project.created).format(
															'MMMM Do YYYY, h:mm:ss a',
														)}
													</td>
													<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
														{project.user?.company?.name}
													</td>
													<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
														{project.user?.first_name} {project.user?.last_name}
													</td>
													<td className="whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
														<button
															onClick={() => {
																Navigate(
																	`/projects/${project.nanoid}/overview/`,
																);
															}}
															className="text-indigo-600 hover:text-indigo-900"
														>
															View
														</button>
													</td>
												</tr>
											))}
										</tbody>
									</table>
									{projectsList.length === 0 && status === 'all' && (
										<div className="text-center my-5">
											<h3 className="mt-2 text-sm font-medium text-gray-900">
												Project not found
											</h3>
										</div>
									)}
									{projectsList.length <= 0 && status !== 'all' && (
										<div className="flex items-center justify-center w-full my-5">
											There are no projects in{' '}
											<p className="text-indigo-400 ml-1 mr-1">
												{PROJECT_STATUS_TEXT_MAPPING[status]}
											</p>
											status
										</div>
									)}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
