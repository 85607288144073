import React from 'react';
import ChoiceList from '../ChoiceList';

const SingleSelectionQuestion = ({
	question,
	handleQuestionSubmit,
	questionList,
	handleReorderChoiceList,
	handleCreateChoice,
	handleChoiceTitleChange,
	handleChoiceImageChange,
	handleChoiceImageTooLarge,
	handleDeleteChoice,
	lastQuestion,
	response = null,
	isReadOnly = false,
	showNextQuestionButton = true,
}) => {
	return (
		<ChoiceList
			question={question}
			handleQuestionSubmit={handleQuestionSubmit}
			questionList={questionList}
			showOther={question.config.show_other}
			handleCreateChoice={handleCreateChoice}
			handleReorderChoiceList={handleReorderChoiceList}
			handleChoiceTitleChange={handleChoiceTitleChange}
			handleChoiceImageChange={handleChoiceImageChange}
			handleChoiceImageTooLarge={handleChoiceImageTooLarge}
			handleDeleteChoice={handleDeleteChoice}
			lastQuestion={lastQuestion}
			response={response}
			isReadOnly={isReadOnly}
			showNextQuestionButton={showNextQuestionButton}
		/>
	);
};

export default SingleSelectionQuestion;
