import { createReduxHistoryContext } from 'redux-first-history';
import { createBrowserHistory } from 'history';
import { configureStore } from '@reduxjs/toolkit';
import authReducer from './slices/authSlice';
import projectsReducer from './slices/projectsSlice';
import usersReducer from './slices/usersSlice';
import promoCodesReducer from './slices/promoCodesSlice';
import dashboardReducer from './slices/dashboardSlice';
import createSagaMiddleware from 'redux-saga';
import rootSaga from './sagas/rootSaga';

const { createReduxHistory, routerMiddleware, routerReducer } =
	createReduxHistoryContext({ history: createBrowserHistory() });

const sagaMiddleware = createSagaMiddleware();

const store = configureStore({
	reducer: {
		router: routerReducer,
		auth: authReducer,
		projects: projectsReducer,
		users: usersReducer,
		promoCodes: promoCodesReducer,
		dashboard: dashboardReducer,
	},
	middleware: [sagaMiddleware, routerMiddleware],
});

sagaMiddleware.run(rootSaga);

export const history = createReduxHistory(store);

export default store;
